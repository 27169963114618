import React, { useEffect, useState } from 'react'
import PrimaryLayout from '../../Components/common/layout/PrimaryLayout'
import HomeLandingSections from '../../Components/common/HomeLandingSections/HomeLandingSections'
import { InnovativeSecurityProductsComponentCardImgs } from '../../Static/constants/imgConstants/imgConstants'
import InnovativeSecurityProductsComponent from '../../Components/common/InnovativeSecurityProductsComponent/InnovativeSecurityProductsComponent'
import PortfoliosSec from '../../Components/main/PortfoliosSec/PortfoliosSec'

function InnovativeSecurityProducts({ setSelectedPortfolio, selectedCard, setSelectedCard, selectedPortfolio, setSelectedProduct }) {
    const [data] = useState([
        {
            id: "ai-based-time-attendance",
            title: "AI Based Time Attendance",
            content: [
                "Secure World's time & attendance management solution simplifies employee timesheet monitoring, calculation of work hours and collection of time & attendance data. Delegated authentication is impossible because it is a biometrics-based time & attendance management system and you can set up various types of work schedule/work shift. It enables human resources management and time & attendance management in one system since you can easily connect with HR or ERP systems and easily see personalized time & attendance reports and calendar view at a glance.",
                "Easy connection with HR and ERP systems.You can connect the system to enable human resources management and time & attendance management in one system. Biometric-based accurate time & attendance management.Flexible work management. Easy work schedule management.Setting various work shifts Providing personalized time & attendance report and calendar view. Time Attendance Mobile enables you to freely control Time Attendance anywhere, anytime on your smart device.Developed based on Time Attendance's web APIs, Time Attendance Mobile provides services appropriate for the mobile era, including user information registration, BioMini-based fingerprint registration, door control, real-time door and zone status notification, and access control event monitoring.",
            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg1,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIconDark1,
            isReverse: false,
        },
        {
            id: "high-level-document-security",
            title: "High Level Document Security",
            content: [
                "CID Application uses multi Biometric identification system to verify the criminal and keep all the records with complete trail in server environment. Automated fingerprint identification systems (AFIS) have been widely used in forensics for the past two decades, and recently they have become relevant for civil applications as well. Whereas CID Application (large-scale biometric applications) require high identification speed and reliability, multi-biometric systems that incorporate both face and fingerprint recognition offer a number of advantages for improving identification quality and usability. System have user level authentication, trailing feature, high rating identification in multi biometric environment with excellent reports feature. Application can be matched up to 160,000 fingerprints or 1,200,000 faces per second on standard Matching Server.",
                "CID application has two major biometric option Face and Finger print, is widely used in the World security. All faces were not created equal, but with Face Finder tool, every face comes out equally well. This easy-to-use face recognition software makes the production of identity cards quicker, easier and more quality controlled. Face Finder eliminates tedious editing work by automatically cropping and standardizing portraits according to preselected International standard criteria. All portraits are the same size, with the same resolution and properly framed. Automatic image enhancement makes camera and lighting adjustments optional. Face Finder can be programmed for automatic or manual operation."

            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg2,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIconDark2,
            isReverse: true,
        },
        {
            id: "smart-meeting",
            title: "Smart Meeting",
            content: [
                "We are counted amongst the leading meeting room planners in UAE. With our smart meeting room solutions, you can easily plan a business meeting as per your schedule and requirements. The meeting room booking solution that we offering to the clients prove out to be very beneficial as it provides access to various options as well. Simplify Conference Room Scheduling With Meeting Planner & Sync With Outlook. The Smart Meeting Room Solutions that we provide are widely known for their higher reliability. Reduce costs and simplify room scheduling with MeetingPlanner, the #1 Microsoft Outlook integrated, mobile device, digital signage and web-based room booking software available.",
                "MeetingPlanner is the only scheduling solution that provides options for Microsoft Outlook integration, web access, mobility options, digital signage integration and Microsoft Exchange Resource synchronization. Secure World has over 15 years of proven experience in Software Solution; we launched smart meeting room solutions for Microsoft Outlook and Exchange experience for scheduling conference rooms, office space, equipment, catering, video conference and other services with MeetingPlanner. MeetingPlanner is installed all over the world with organizations ranging from large global fortune 100 companies, hospitals, non-profits, government and educational institutions."

            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg13,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIconDark3,
            isReverse: false,
        },
        {
            id: "visitor-management",
            title: "Visitor Management",
            content: [
                "Visitor management refers to tracking the usage of a public building or site.By gathering increasing amounts of information, a Visitor Management System can record the usage of the facilities by specific visitors and provide documentation of visitor's whereabouts. For organizations that regularly host events and conferences on-site, the pre-booking feature allows visitors and guests to pre-register their attendance prior to arriving, effectively reducing their check-in time from a 1-2 minute process down to a couple of seconds. With some simple configuration, Visitor Management solutions can be designed to request unique reference numbers as the final stage of a check-in process, making the check-in process even easier.",
                "The final stage of a Visitor Management solutions check-in process is the badge printing. Regardless of whether a visitor pre-registers or completes the check-in on arrival, customised ID badges can be printed in seconds as part of a modern Visitor Management solution. In some cases where Access Control integrations have been made, ID badges can be scanned at control points to gain access to zones that have been configured to grant authorisation. For any future-proof solution on the market, it’s important to consider how well it integrates with other solutions."

            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg4,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIconDark4,
            isReverse: true,
        },
        {
            id: "q-management",
            title: "Q Management",
            content: [
                "Are you facing problems like unorganized waiting areas, increased customer waiting time, increased employee idle time, frustrated customers due to unfair serving, unable to monitor the performance of a branch located in a distant location, in short, are you unable to create a unique customer experience which can be your competitive edge? Relax!!! We can solve your problem. Our Q management system in Dubai is designed not only to address all your problems but also help you create an environment where your customers can feel at home even while waiting. Our queue management system in Dubai can redefine customer experience and help you create a competitive edge.",
                "Our Q management system in Dubai is ideal for Banks, Telecom Service Centers, Government Institutions, Hospitals, Retail, Airlines, Embassies or any other organization which encounters heavy flow of customers. Moreover, our queue management system In Dubai is designed to help you create a service delivery environment where customers can enjoy a memorable experience resulting into the highest levels of customer satisfaction, consequently making them more loyal to your brand.",
            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg5,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIconDark5,
            isReverse: false,
        },
        {
            id: "passport-solution",
            title: "Passport Solution",
            content: [
                "The International Civil Aviation Organization, a Quebec based group, developed standards for Machine Readable Travel Documents (MRTDs), including passports and visas. These MRTD make it easy for automated systems to scan a travel document. If a country decides to check all visitors against a known criminal’s data reading the information automatically with a computer will be much faster and less prone to error than if the immigration agent needs to type the information in by hand. Security concerns, developing technologies and emerging standards have led national governments to pursue the issuance of more sophisticated machine-readable travel documents (MRTD) to their citizens.",
                "Commonly known as 'e-Passports,' these documents contain a chip that stores information that can be verified against the data on the passport. The E Passport Solution that we are offering to the clients helps the clients in saving their time and energy as well. In order to facilitate interoperability across countries, the International Civil Aviation Organization (ICAO) has set global standards for e-Passports. Since the e-Passport contains sensitive personal information, the security and integrity of the e-Passport are critical. As a result, two e-Passport standards have been developed to help migrate countries from traditional paper-based travel documents.",
            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg6,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIconDark6,
            isReverse: true,
        },
        {
            id: "cheque-printing",
            title: "Cheque Printing",
            content: [
                "The search for the trusted cheque print solution comes to an end at Secure World. Our cheque print solution services are widely utilized by many companies in UAE. ChequeSystem software prints your cheques completely. It can print payee, date, amount, 'a/c payee only and all others. The cheque is ready to issue once signed. Batch printing function makes your cheque issuing process even simpler. ChequeSystem provides powerful chequebook management. You can review all the issued cheques at any time you want. Multiple chequebooks are supported to fit your needs. ChequeSystem provides two reports: Account Report and Payee Report. These two reports provide comprehensive queries on the date, chequebooks account and payee",
                "You can also create your cheques first and use the batch printing function to print a sequence of cheques in batch. ChequeSystem software supports Laser, InkJet, and Dot-Matrix printer. You can save cheque information as cheque template for those frequently issued cheques. The template can be recalled which make your cheque preparation easier. Organized Payee List allows you to load the payee information into the cheques with a single click. You can prepare your cheque even faster and easier. Account report generates Cheque summary on chequebook for any time range. Your chequebook history is always ready.",
            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg7,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIconDark7,
            isReverse: false,
        },
        {
            id: "enterprise-access-control",
            title: "Enterprise Access Control",
            content: [
                "Access control is a solution which is absolutely required in all fields and scales as a basic element of security. From research institutes, data centers and secure areas to factories, apartments, offices, construction sites, hospitals, schools and banks, it is widely used at places that are trying to physically protect property or information from unauthorized people. Secure World's access control solution is a biometrics-based system, providing greatly improved security than the access control solutions that use RF card only.",
                "Secureworld  have web-based, open, and integrated security platform that provides comprehensive functionality for access control. Featuring a modular, flexible framework, the platform provides a customized system depending on system scale, number of users and system structure that was used. We provide a customized system with various Control systems.It is the most ideal solution equipped with all features that a user needs such as easy user management, a various zone management, elevator management or real-time monitoring.",
            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg8,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIconDark8,
            isReverse: true,
        },
    ]);
    
    useEffect(() => {
        setTimeout(() => {
            if(
                setSelectedPortfolio
            ){
                window.location.href= "#"+selectedPortfolio
            }
        }, 1000);
    }, [selectedPortfolio]);

    return (
        <div className='innovative-security-products'>
            <PrimaryLayout
                showNavbar={true}
                showContactUs={false}
                showFooterMain={true}
                showFooterMini={true}
                isContactUsAltBg={false}
                setSelectedPortfolio={setSelectedPortfolio}
                setSelectedCard={setSelectedCard}
                setSelectedProduct={setSelectedProduct}
            >
                <HomeLandingSections
                    title1={`Revolutionizing`}
                    title2={`\nSecurity`}
                    subtitle={`Innovative Solutions for a Safer Tomorrow.\nRedefining Security Standards with\nVisionary Technology.`} 
                    route={"security"}
                />
                <InnovativeSecurityProductsComponent />
                {
                    data.map((item) => {
                        return (
                            <PortfoliosSec
                                id={item.id}
                                title={item.title}
                                content={item.content}
                                bgImg={item.bgImg}
                                isReverse={item.isReverse}
                                isHeadingIcon={item.icon}
                            />
                        )
                    })
                }
            </PrimaryLayout>
        </div>
    )
}

export default InnovativeSecurityProducts