import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import './App.sass';
import './global.sass';
import Portfolios from './Pages/Portfolios/Portfolios';
import AboutUs from './Pages/AboutUs/AboutUs';
import FintechSolutions from './Pages/FintechSolutions/FintechSolutions';
import InnovativeSecurityProducts from './Pages/InnovativeSecurityProducts/InnovativeSecurityProducts';
import { useState } from 'react';
import Products from './Pages/Products/Products';
import PrimaryLayout from './Components/common/layout/PrimaryLayout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [selectedPortfolio, setSelectedPortfolio] = useState('')
  const [selectedCard, setSelectedCard] = useState('')
  const [selectedProduct, setSelectedProduct] = useState('')

  return (
    <div className="App overflow-y-hidden">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        bodyClassName={"toaster-fonts"}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route path="/" element={<Home setSelectedProduct={setSelectedProduct} setSelectedPortfolio={setSelectedPortfolio} setSelectedCard={setSelectedCard} />} />
        <Route path="/products" element={<Products setSelectedProduct={setSelectedProduct} selectedProduct={selectedProduct} setSelectedPortfolio={setSelectedPortfolio} setSelectedCard={setSelectedCard} />} />
        <Route path="/portfolios" element={<Portfolios setSelectedProduct={setSelectedProduct} setSelectedPortfolio={setSelectedPortfolio} setSelectedCard={setSelectedCard} selectedPortfolio={selectedPortfolio} />} />
        <Route path="/about-us" element={<AboutUs setSelectedProduct={setSelectedProduct} setSelectedPortfolio={setSelectedPortfolio} setSelectedCard={setSelectedCard} />} />
        <Route path="/fintech-solutions" element={<FintechSolutions setSelectedProduct={setSelectedProduct} setSelectedPortfolio={setSelectedPortfolio} setSelectedCard={setSelectedCard} />} />
        <Route path="/innovative-security-products" element={<InnovativeSecurityProducts setSelectedProduct={setSelectedProduct} setSelectedPortfolio={setSelectedPortfolio} setSelectedCard={setSelectedCard} selectedCard={selectedCard} selectedPortfolio={selectedPortfolio} />} />
      </Routes>
      <PrimaryLayout setSelectedPortfolio={setSelectedPortfolio} setSelectedCard={setSelectedCard} setSelectedProduct={setSelectedProduct} />
    </div>
  );
}

export default App;
