import React, { useEffect, useState } from 'react'
import { HomeLandingSectionIcons } from '../../../Static/constants/imgConstants/imgConstants';

function HomeLandingSections({ title1, title2, subtitle, route }) {
    const activeNav = window.location.href.split("/")[window.location.href.split("/").length - 1];

    const [activeRoute, setActiveRoute] = useState('');

    useEffect(() => {
        switch (route) {
            case "fintech":
                setActiveRoute("home-landing-sections-1");
                return;
            case "security":
                setActiveRoute("home-landing-sections-3");
                return;
            case "about-us":
                setActiveRoute("home-landing-sections-2");
                return;
            default:
                setActiveRoute("home-landing-sections");
                return;
        }

    }, [route])

    return (
        <div className={`${activeRoute} w-full h-[64rem]`}>
            <div className='w-full my-auto h-full backdrop-blur-xl backdrop-opacity-100 bg-white/30 lg:bg-transparent lg:backdrop-filter-none'>
                <div className='home-landing-sections-content flex flex-col justify-center items-start container-lg my-auto h-full'>
                    <h1 className='home-landing-sections-heading text-[5rem] sm:text-[7rem] text-[#008ED4] text-start mt-[3.2rem]'>
                        <pre className='font-[600]'>
                            {title1}
                            <span className="text-[#1E3365] font-[600]">
                                {title2}
                            </span>
                        </pre>
                    </h1>
                    <h1 className='home-landing-sections-heading text-[2.4rem] sm:text-[3rem] text-[#3E3E3E] text-start font-[300] my-4'>
                        <pre className=''>
                            {subtitle}
                        </pre>
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default HomeLandingSections