import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, setIsMenuOpen, isMenuOpen) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */

        
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsMenuOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
    console.log('====================================');
    console.log("isMenuOpen :", props.isMenuOpen);
    console.log('====================================');
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.setIsMenuOpen, props.isMenuOpen);

    return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired
};

export default OutsideAlerter;
