import React, { useState } from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import ServicesCard from '../../common/ServicesCard/ServicesCard'
import { ServicesIcons } from '../../../Static/constants/imgConstants/imgConstants';

function ServicesSec({ isServicesAlt, subHeading }) {
    const [data] = useState([
        {
            id: "Smartron",
            title: `Smartron`,
            icon: ServicesIcons.serviceIcon1,
            path: "/services/product-engineering",
            content: "Simplify Conference Room Scheduling with MeetingPlanner",
        },
        {
            id: "CID Application",
            title: `CID Application`,
            icon: ServicesIcons.serviceIcon2,
            path: "/services/data-governance-and-analytics",
            content: "Multi Biometric identification system to verify the criminal",
        },
        {
            id: "E-Passport",
            title: `E-Passport`,
            icon: ServicesIcons.serviceIcon3,
            path: "/services/blockchain",
            content: "Machine Readable Travel Documents (MRTDs)",
        },
        {
            id: "Loyalty Point",
            title: `Loyalty Point`,
            icon: ServicesIcons.serviceIcon4,
            path: "/services/cloud-engineering",
            content: "One card Fits All multi application loyalty cards",
        },
        {
            id: "product-engineering",
            title: `Event Management `,
            icon: ServicesIcons.serviceIcon5,
            path: "/services/product-engineering",
            content: "Complete Event Management system",
        },
        {
            id: "data-governance-and-analytics",
            title: `Cheque Print`,
            icon: ServicesIcons.serviceIcon6,
            path: "/services/data-governance-and-analytics",
            content: "No Handwriting, No Bulky ‘Cheque Writer",
        },
        {
            id: "blockchain",
            title: `Q- Management`,
            icon: ServicesIcons.serviceIcon7,
            path: "/services/blockchain",
            content: "Complete Q- Management solution",
        },
        {
            id: "cloud-engineering",
            title: `Assetron`,
            icon: ServicesIcons.serviceIcon8,
            path: "/services/cloud-engineering",
            content: "Comprehensive asset management software",
        },
    ]);

    return (
        <div className='services flex flex-col justify-start items-start container-lg'>
            {!isServicesAlt &&
                <SectionHeaders
                    contentPrimary="Our Products "
                    contentSecondary=" & Services"
                    classes="text-[4rem] lg:text-[5rem] font-[500] text-[#008ED4] !mt-[2rem]"
                />
            }
            <h3 className='text-[2rem] sm:text-[2.4rem] text-[#4E617C] font-[400] my-[2rem] tracking-wider text-justify'>
                {`At SECURE WORLD, we offer a wide range of products and services to meet your IT needs. We provide expert care for networks, printers, systems, devices, applications, and your staff. We deliver enterprise-level, Fortune 500-quality IT infrastructure to small and medium-sized businesses at a price point you can afford. We do this by investing in tools and systems that deliver the solutions you need. This investment in people, process, and technology is shared across our system with thousands of our clients.`}
            </h3>
            <div className='grid grid-cols-2 md:grid-cols-4 mb-[5rem]'>
                {
                    data.map((item, index) => {
                        return (
                            <ServicesCard
                                index={index}
                                id={item.id}
                                title={item.title}
                                content={item.content}
                                icon={item.icon}
                                isServicesAlt={isServicesAlt}
                                path={item.path}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ServicesSec
