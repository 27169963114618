import React from 'react'
import { ReadMoreIcons } from '../../../Static/constants/imgConstants/imgConstants'

function LatestInsightsCard({bgImg}) {
    return (
        <div className='latest-insights-card cursor-pointer flex flex-col justify-start items-start mx-[1.2rem] z-10 bg-[#F1F5FF]'>
            <div className='card-img-top w-full h-[32rem] sm:h-[25rem] lg:h-[27.5rem]'>
                <img 
                    className='w-full h-full'
                    src={bgImg}
                    alt='card-img'
                />
            </div>
           {/*  <div className='card-content-bottom flex flex-col justify-between w-full h-[15rem] sm:h-[18.5rem] p-[2rem]'>
                <h1 className='text-[1.8rem] lg:text-[2rem] text-[#202020] font-[300]'>
                    The client is an American multinational
                    company that operates, and franchises
                    lodging.
                </h1>
                <div className='flex justify-between items-center '>
                    <div className='flex justify-start items-center cursor-pointer'>
                        <div className='user-profile-pic w-[2.8rem] h-[2.1rem] rounded-full mr-3'>
                            <img 
                                className='w-full h-full'
                                src={ReadMoreIcons.ReadMoreIcon}
                                alt='icon'
                            />
                        </div>
                        <h3 className='text-[#0478FF] text-sm font-[400]'>
                            Read More
                        </h3>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default LatestInsightsCard
