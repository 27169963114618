import React from 'react'
import { TestimonyCardImgs } from '../../../Static/constants/imgConstants/imgConstants';

const handleDragStart = (e) => e.preventDefault();

function TestimonyCard({ message, name, role, image, imageWidth, OrganizationLogo, classNames }) {
    return (
        <div onDragStart={handleDragStart} className={`testimony-card w-full flex justify-evenly items-center mt-[1.2rem] [@media(min-width:1300px)]:mt-[2.4rem] cursor-pointer ${classNames}`} onClick={() => {window.open('https://www.facebook.com/SecureWorldme/')}}>
            <div className={imageWidth ? imageWidth : "w-[30%]"}>
                <img
                    className='w-full h-full'
                    src={image}
                    alt='img'
                />
            </div>
            <div className='testimony-card w-[60%] h-full ml-auto mr-[2rem] xs:mr-[5rem]'>
                <div className='testimony-card w-full h-full'>
                    <div className='flex flex-col justify-between items-start'>
                        <div className="flex justify-between items-start">
                            <h1 className="text-[3.2rem] [@media(min-width:1300px)]:text-[4.4rem] text-[#04518C]">
                                {name}
                            </h1>
                            
                        </div>
                        <h2 className='text-base lg:text-[2rem] [@media(min-width:1300px)]:text-[2.3rem] text-[#4E617C] mt-[2rem] [@media(min-width:1300px)]:mt-[5rem] text-justify'>
                            <div className='quotation-img w-[2.2rem] [@media(min-width:1300px)]:w-[3.1rem] inline-block mr-[2rem] rotate-180 -mt-[2rem]'>
                                <img
                                    className='w-full h-full'
                                    src={TestimonyCardImgs.QuotationIcon}
                                    alt='img'
                                />
                            </div>
                            {message}
                            <div className='quotation-img w-[2.2rem] [@media(min-width:1300px)]:w-[3.1rem] inline-block ml-[2rem]'>
                                <img
                                    className='w-full h-full'
                                    src={TestimonyCardImgs.QuotationIcon}
                                    alt='img'
                                />
                            </div>
                        </h2>
                        <div className='flex justify-between items-center w-full mt-6 [@media(min-width:1300px)]:mt-10'>
                            <div className='flex flex-col justify-between items-start'>
                                <h1 className='text-[2rem] [@media(min-width:1300px)]:text-[3rem] text-[#010001] font-[600]'>
                                    
                                </h1>
                                <h1 className='text-[1.3rem] text-[#989DAE] mb-[0] [@media(min-width:1300px)]:mb-[4.4rem]'>
                                    {role}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonyCard
