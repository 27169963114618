import React from 'react'
import { InfominezBannerImgs } from '../../../Static/constants/imgConstants/imgConstants'

function InfominezBanner() {
    return (
        <div className='secure-world-banner w-full h-[36rem] md:h-[22rem] mb-12'>
            <div className='flex flex-col md:flex-row justify-around md:justify-between items-center container-lg h-full my-auto'>
                <div className='secure-world-logo cursor-pointer h-[10rem] mr-10 hover:shadow-[0_3.4rem_8.4rem_0_rgba(63,42,131,0.15)]' onClick={()=> window.open("https://www.infominez.com/")}>
                    <img 
                        className='w-full h-full'
                        src={InfominezBannerImgs.websiteLogo}
                        alt='infominez-logo'
                    />
                </div>
                <div className='flex flex-col justify-center items-center md:items-start text-[#FFFFFF] mx-8'>
                    <h1 className='secure-world-heading-main text-[2.6rem] md:text-[3.2rem] lg:text-[4rem] font-[700] uppercase tracking-tight -mb-[1.6rem]'>
                        Strategic Partners
                    </h1>
                    <h1 className='secure-world-heading text-[2rem] md:text-[2.6rem] lg:text-[3rem] font-[500] my-[1.2rem] md:my-0'>
                        Strategic Partnerships with Industry Leaders.
                    </h1>
                    <div className="w-[100%] my-[2rem] bg-[#3EBAF6] h-[0.1rem]"></div>
                    <h1 className='secure-world-subheading text-lg md:text-xl lg:text-2xl text-[#FFFFFF]/[0.8] font-[400] leading-10 tracking-tight text-center md:text-left'>
                        Revolutionizing Fintech and banking, transforming the future, driving innovation for financial services.
                    </h1>
                </div>
            </div>
            
        </div>
    )
}

export default InfominezBanner
