import React from 'react'
import { PrestigiousClientsImgs } from '../../../Static/constants/imgConstants/imgConstants';
import AliceCarousel from 'react-alice-carousel'
import PrestigiousClientsCard from '../../common/PrestigiousClientsCard/PrestigiousClientsCard';
import SectionHeaders from '../../common/Headers/SectionHeaders';

function PrestigiousClients() {

    const responsive = {
        0: {
            items: 2
        },
        768: {
            items: 3
        },
        993: {
            items: 4,
            itemsFit: 'contain'
        },
        1320: {
            items: 5,
            itemsFit: 'contain'
        },
    };

    const items = [
        <PrestigiousClientsCard mainIcon={PrestigiousClientsImgs.PrestigiousClientsImg1} />,
        <PrestigiousClientsCard mainIcon={PrestigiousClientsImgs.PrestigiousClientsImg2} />,
        <PrestigiousClientsCard mainIcon={PrestigiousClientsImgs.PrestigiousClientsImg3} />,
        <PrestigiousClientsCard mainIcon={PrestigiousClientsImgs.PrestigiousClientsImg4} />,
        <PrestigiousClientsCard mainIcon={PrestigiousClientsImgs.PrestigiousClientsImg5} />,
    ];

    return (
        <div className="client-testimonial w-full bg-[#E2EAFE]">
            <div className='client-testimonial-container container-lg flex flex-col justify-center items-start'>
                <SectionHeaders
                    contentPrimary="Our Prestigious "
                    contentSecondary="Clients"
                    classes="text-[4rem] lg:text-[5rem] font-[500] mt-[2rem] text-[#008ED4]"
                />
                <h2 className='text-[2rem] md:text-[2.6rem] lg:text-[3.4rem] text-[#3E3E3E] font-[500] mt-[1rem]'>
                    Elevating Excellence: Our Esteemed Clientele.
                </h2>
                <p className="text-[#3E3E3E] text-base md:text-[1.8rem] lg:text-[2.1rem]">Partnerships with Industry Leaders: Showcasing Our Prestigious Clientele and Collaborative Success Stories.</p>
                <AliceCarousel
                    items={items}
                    autoPlayInterval={4000}
                    infinite
                    autoPlayStrategy='default'
                    keyboardNavigation={true}
                    autoPlay
                    mouseTracking
                    responsive={responsive}
                    disableDotsControls={true}
                />
            </div>
        </div>
    )
}

export default PrestigiousClients