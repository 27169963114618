import React from 'react'

function OfferingsInfoComponentCard({ id, title, content, bgImg, icon }) {
    return (
        <div className={`offerings-info-component-card cursor-pointer group overflow-hidden`}>
            <a href={`#${id}`} className='flex flex-col justify-start items-start bg-white h-full'>
                <div className="offerings-info-component-card-img w-full h-[17.5rem] sm:h-[20rem] lg:h-[22rem] group-hover:scale-101 group-hover:blur-[2px] overflow-hidden z-10">
                    <img src={bgImg} alt="" className="w-full h-full" />
                </div>
                <div className='w-[7rem] sm:w-[9rem] h-[7rem] sm:h-[9rem] -mt-[4rem] sm:-mt-[5.5rem] ml-[20%] z-20 group-hover:scale-110 transition ease-in-out'>
                    <img
                        className='w-full h-full rounded-[.8rem]'
                        src={icon}
                        alt='card-img'
                    />
                </div>
                <div className="p-[2rem]">
                    <pre className='text-sm sm:text-[10px] [@media(min-width:1319px)]:text-base font-[600] text-[#04518C] text-Montserrat-sans-serif text-start my-[.2rem] sm:my-[1.2rem]'>
                        {title}
                    </pre>
                    <p className='text-[10px] [@media(min-width:1319px)]:text-sm font-[500] text-start my-[.2rem] sm:my-[1.2rem] text-DM-Sans-sans-serif'>
                        {content}
                    </p>
                </div>
            </a>
        </div>
    )
}

export default OfferingsInfoComponentCard