import React from 'react'
import { useNavigate } from 'react-router-dom';

function PortfolioCard({ id, title, content, isSelected, mainIcon, srNumber, setSelectId, setSelectedPortfolio }) {
    const navigate = useNavigate(); 

    return (
        <div
            className={`industries-sec-card cursor-pointer h-[34rem] m-[3rem] min-w-[36rem] flex flex-col justify-start items-start px-[4rem] py-[5rem] ${isSelected ? "bg-[#008ED4]" : "bg-[#F9FAFF]"} shadow-[0_1.4rem_2rem_0_rgba(6,67,110,0.10)] rounded-[.8rem]`}
            onMouseEnter={() => setSelectId(id)}
            onMouseLeave={() => setSelectId(null)}
            onClick={()=> {setSelectedPortfolio(id);navigate("portfolios")}}
        >
            <div className='flex justify-between items-end w-full max-h-[9rem]'>
                <div className='services-card-icon h-[6rem] pb-2'>
                    <img
                        className='w-full h-full'
                        src={mainIcon}
                        alt='icon'
                    />
                </div>
                <h1 className={`text-[9rem] ${isSelected ?  "text-[#008ED4] white-outlined-text" : "text-[#F9FAFF] blue-outlined-text"} font-[600] -mt-[4rem] -mb-[3rem] leading-[.1rem]`}>
                    {srNumber}
                </h1>
            </div>
            <pre className={`text-3xl font-[600] ${isSelected ? "text-[#FFFFFF]" : "text-[#3C436A]"} text-left my-[1.6rem]`}>
                {title}
            </pre>
            <h2 className={`text-lg font-[500] text-DM-Sans-sans-serif ${isSelected ? "text-[#FFFFFF]" : "text-[#707F96]"} text-left my-[1.6rem]`}>
                {content}
            </h2>
        </div>
    )
}

export default PortfolioCard