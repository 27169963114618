import React, { useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import PortfolioCard from '../../common/PortfolioCard/PortfolioCard'
import { PortFolioImgs } from '../../../Static/constants/imgConstants/imgConstants';
import SectionHeaders from '../../common/Headers/SectionHeaders';

function PortfolioSec({ setSelectedPortfolio }) {
    
    const [selectId, setSelectId] = useState(null);

    const responsive = {
        0: { 
            items: 1
        },
        576: { 
            items: 2
        },
        993: { 
            items: 3
        },
        1400: {
            items: 4, 
            itemsFit: 'contain'
        },
    };

    const items = [
        <PortfolioCard
            id="web-development"
            title={"Web Development"}
            content={"Using our web application development services, you will get the digital tools that solve your challenges"}
            isSelected={selectId === "web-development" ? true : false}
            setSelectId={setSelectId}
            mainIcon={selectId === "web-development" ? PortFolioImgs.PortfolioImgLight1 : PortFolioImgs.PortfolioImg1}
            srNumber={'01'}
            setSelectedPortfolio={setSelectedPortfolio}
        />,
        <PortfolioCard
            id="app-development"
            title={"App Development"}
            content={"Working with an experienced mobile applications developer, you get predictably successful results"}
            isSelected={selectId === "app-development" ? true : false}
            setSelectId={setSelectId}
            mainIcon={selectId === "app-development" ? PortFolioImgs.PortfolioImgLight2 : PortFolioImgs.PortfolioImg2}
            srNumber={'02'}
            setSelectedPortfolio={setSelectedPortfolio}
        />,
        <PortfolioCard
            id="assetron"
            title={"Assetron"}
            content={"Our good asset management solution will help you optimize performance vs. risk taken"}
            isSelected={selectId === "assetron" ? true : false}
            setSelectId={setSelectId}
            mainIcon={selectId === "assetron" ? PortFolioImgs.PortfolioImgLight3 : PortFolioImgs.PortfolioImg3}
            srNumber={'03'}
            setSelectedPortfolio={setSelectedPortfolio}
        />,
        <PortfolioCard
            id="enterprise-automation"
            title={"Enterprise Automation"}
            content={"We provide comprehensive business process automation solution covering all business process management steps"}
            isSelected={selectId === "enterprise-automation" ? true : false}
            setSelectId={setSelectId}
            mainIcon={selectId === "enterprise-automation" ? PortFolioImgs.PortfolioImgLight4 : PortFolioImgs.PortfolioImg4}
            srNumber={'04'}
            setSelectedPortfolio={setSelectedPortfolio}
        />,
        <PortfolioCard
            id="smartron"
            title={"Smartron"}
            content={"We are counted amongst the leading meeting room planners in UAE"}
            isSelected={selectId === "smartron" ? true : false}
            setSelectId={setSelectId}
            mainIcon={selectId === "smartron" ? PortFolioImgs.PortfolioImgLight4 : PortFolioImgs.PortfolioImg4}
            srNumber={'05'}
            setSelectedPortfolio={setSelectedPortfolio}
        />,
        <PortfolioCard
            id="queue-management"
            title={"Queue Management"}
            content={"Our Q management system in Dubai is designed to help you create an environment where your customers can feel at home even while waiting."}
            isSelected={selectId === "queue-management" ? true : false}
            setSelectId={setSelectId}
            mainIcon={selectId === "queue-management" ? PortFolioImgs.PortfolioImgLight4 : PortFolioImgs.PortfolioImg4}
            srNumber={'06'}
            setSelectedPortfolio={setSelectedPortfolio}
        />,
        <PortfolioCard
            id="time-attendance"
            title={"Time & Attendance"}
            content={"Our time & attendance management solution simplifies employee timesheet monitoring, calculation of work hours & attendance data."}
            isSelected={selectId === "time-attendance" ? true : false}
            setSelectId={setSelectId}
            mainIcon={selectId === "time-attendance" ? PortFolioImgs.PortfolioImgLight4 : PortFolioImgs.PortfolioImg4}
            srNumber={'07'}
            setSelectedPortfolio={setSelectedPortfolio}
        />,
        <PortfolioCard
            id="visitor-management"
            title={"Visitor Management"}
            content={"Visitor management refers to tracking the usage of a public building or site"}
            isSelected={selectId === "visitor-management" ? true : false}
            setSelectId={setSelectId}
            mainIcon={selectId === "visitor-management" ? PortFolioImgs.PortfolioImgLight4 : PortFolioImgs.PortfolioImg4}
            srNumber={'08'}
            setSelectedPortfolio={setSelectedPortfolio}
        />,
    ]

    return (
        <div className="portfolio-sec relative w-full">
            <div className='container-lg flex flex-col justify-between items-start h-[74rem] z-10'>
                <SectionHeaders
                    contentPrimary="Our "
                    contentSecondary="Portfolio"
                    classes="text-[4rem] lg:text-[5rem] font-[500] mt-[2rem] text-[#008ED4]"
                />
                <h2 className='text-[2rem] lg:text-[3rem] text-[#3E3E3E] font-[500] leading-[3rem] lg:leading-[4rem]'>
                    We are purely an IT projects company into Software & IT Automation with Latest Surveillance Securities.
                </h2>
                <p className="text-[#3E3E3E] text-[2.1rem]">
                    Revolutionizing Surveillance Security Through IT Automation.
                </p>
                <AliceCarousel autoPlayInterval={4000} infinite autoPlayStrategy='default' autoPlay mouseTracking items={items} responsive={responsive} keyboardNavigation={true} />
            </div>
        </div>
    )
}

export default PortfolioSec