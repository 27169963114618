import React from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import { HomeLandingSectionIcons, ourOfferingsIcons } from '../../../Static/constants/imgConstants/imgConstants'
import { useNavigate } from 'react-router-dom';

function OurOfferingsSec() {
    const navigate = useNavigate();
    return (
        <div className='our-offerings-sec w-full'>
            <div className='flex flex-col justify-start items-start container-lg'>
                <SectionHeaders
                    contentPrimary="Our "
                    contentSecondary="Offerings"
                    classes="text-[4rem] lg:text-[5rem] font-[500] text-[#00E0FF] mt-[8rem]"
                />
                <h2 className='text-[2rem] lg:text-[3rem] text-white font-[500] mt-[1.2rem]'>
                    Secure World: Enterprise technology solution providers through world-class Products and Services
                </h2>
                <p className='text-[1.6rem] lg:text-[2.2rem] font-[400] text-white text-justify my-[1rem]'>
                    Secure World leads the industry as enterprise technology solution providers, offering world-class Products and Services. With a steadfast commitment to excellence, we empower businesses with innovative solutions tailored to their needs, ensuring optimal performance and security.
                </p>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-[4rem] place-items-stretch my-[2rem] lg:my-[4rem] min-h-max'>
                    <div className='bg-white rounded-[1rem] p-[4rem] flex flex-col justify-between'>
                        <div className="flex justify-start items-center">
                            <div className='our-offerings-icons min-w-[10rem] h-[10rem] mr-[2rem] bg-[#1F386B] rounded-[1.2rem] p-[2rem]'>
                                <img
                                    className='w-full h-full'
                                    src={ourOfferingsIcons.OfferingIconBlue1}
                                    alt='icon'
                                />
                            </div>
                            <h2 className='text-[2.2rem] 2xl:text-[4rem] text-[#04518C] font-[500] mt-auto mb-[2rem] pr-2 tracking-tight'>
                                Fintech Solutions
                            </h2>
                        </div>
                        <div className='flex flex-col justify-between items-start mt-[2rem] xl:mt-[1.2rem] [@media(min-width:1300px)]:mt-[2rem] text-ellipsis overflow-hidden py-[2rem]'>
                            <p className='text-[1.6rem] lg:text-[1.8rem] 2xl:text-[2.4rem] text-justify'>
                                SECURE WORLD, a name synonymous with security excellence for the past 15 years in the UAE,
                                is excited to introduce a new chapter in our story. We are committed to our mission of making
                                finance accessible, secure, and efficient for every individual and business in the UAE.In a world
                                where technology shapes the future of finance, SECURE WORLD is proud to embark on its new journey
                                into the Fintech market of the UAE. With a successful history in smart security systems, we're ready to bring innovation, trust, and a commitment to excellence to the financial sector.
                            </p>
                        </div>
                        <div className="text-[2rem] text-white w-[21.5rem] flex justify-center items-center cursor-pointer bg-[#1F386B] px-8 rounded-full py-2 hover:scale-110 ease-in"
                            onClick={() => navigate("/fintech-solutions")}
                        >
                            Learn More
                            <div className="w-[2rem] mx-[1rem]">
                                <img
                                    className=""
                                    src={HomeLandingSectionIcons.HomeLandingActionIcon}
                                    alt='icon'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='bg-white rounded-[1rem] p-[4rem] flex flex-col justify-between'>
                        <div className="flex justify-start items-center">
                            <div className='our-offerings-icons min-w-[10rem] h-[10rem] mr-[2rem] bg-[#1F386B] rounded-[1.2rem] p-[2rem]'>
                                <img
                                    className='w-full h-full'
                                    src={ourOfferingsIcons.OfferingIconBlue2}
                                    alt='icon'
                                />
                            </div>
                            <h2 className='text-[2.2rem] 2xl:text-[4rem] text-[#04518C] font-[500] mt-auto mb-[2rem] pr-2 tracking-tight'>
                                Innovative Security Products
                            </h2>
                        </div>
                        <div className='flex flex-col justify-start items-start mt-[2rem] xl:mt-[1.2rem] [@media(min-width:1300px)]:mt-[2rem] [@media(min-width:1319px)]:mt-[0] [@media(min-width:1360px)]:mt-[2rem] text-ellipsis overflow-hidden py-[2rem]'>
                            <p className='text-[1.6rem] lg:text-[1.8rem] 2xl:text-[2.4rem] text-justify'>
                                At SECURE WORLD, we offer a wide range of products and services to meet your IT needs.
                                We provide expert care for networks, printers, systems, devices, applications, and your
                                staff. We deliver enterprise-level, Fortune 500-quality IT infrastructure to small and
                                medium-sized businesses at a price point you can afford.
                            </p>
                            <p className='text-[1.6rem] lg:text-[1.8rem] 2xl:text-[2.4rem] text-justify lg:my-[1.4rem] [@media(min-width:1300px)]:my-[0] 2xl:my-[1.4rem]'>
                                We do this by investing in tools and systems that deliver the solutions you need.
                                This investment in people, process, and technology is shared across our system with
                                thousands of our clients.
                            </p>
                        </div>
                        <div className="text-[2rem] text-white w-[21.5rem] flex justify-center items-center cursor-pointer bg-[#1F386B] px-8 rounded-full py-2 hover:scale-110 ease-in" onClick={() => navigate("/innovative-security-products")}>
                            Learn More
                            <div className="w-[2rem] mx-[1rem]">
                                <img src={HomeLandingSectionIcons.HomeLandingActionIcon} alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurOfferingsSec
