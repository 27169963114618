import React from 'react'
import Navbar from '../../main/Navbar/Navbar'
import FooterMain from '../FooterMain/FooterMain'
import FooterMini from '../FooterMini/FooterMini'
import ContactUs from '../ContactUs/ContactUs'

function PrimaryLayout({showNavbar, showFooterMain, children, showFooterMini, showContactUs, isContactUsAltBg, setSelectedPortfolio, setSelectedCard, setSelectedProduct}) {
  return (
    <div className='primary-layout'>
        {showNavbar ? <Navbar setSelectedPortfolio={setSelectedPortfolio}/> : null}
        {children}
        {showContactUs ? <ContactUs isContactUsAltBg={true}/> : null}
        {showFooterMain ? <FooterMain setSelectedCard={setSelectedCard} setSelectedProduct={setSelectedProduct} setSelectedPortfolio={setSelectedPortfolio}/> : null}
        {showFooterMini ? <FooterMini/> : null}
    </div>
  )
}

export default PrimaryLayout