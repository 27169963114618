import React, { useEffect, useRef, useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

function PortfoliosSec({ id, isReverse, bgImg, title, content, isHeadingIcon }) {

  const responsive = {
    0: {
      items: 1
    }
  };
  
  const [expandedItemId, setExpandedItemId] = useState(null);
  const [imageHeight, setImageHeight] = useState(360);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const imageHeight = textRef.current.parentNode.clientHeight;
      if(imageHeight){
        setImageHeight(imageHeight - 38);
      }
    }
  }, [id]);

  const toggleExpand = (id) => {
    if(id !== expandedItemId){
      setExpandedItemId(id)
    }else {
      setExpandedItemId(null)
    }
  };

  const PortfoliosSecCard = () => {
    return(
      <div key={id} className={`container-lg overflow-hidden`}>
        <div className={`portfolios-sec-card-img ${isReverse ? "float-right ml-[3.2rem]" : "float-left mr-[3.2rem]"} w-[48%]`}>
          {!Array.isArray(bgImg) ? 
            <img ref={textRef} src={bgImg} className='w-full h-full' />
            :
            <AliceCarousel
              items={bgImg?.map((item) => {return(<img ref={textRef} src={item} className='w-full h-full'/>)})}
              autoPlayInterval={2000}
              infinite
              autoPlayStrategy='default'
              keyboardNavigation={true}
              autoPlay
              mouseTracking
              responsive={responsive}
              disableDotsControls={false}
            />
          }
        </div>
        <div style={imageHeight > 0 ? { minHeight: imageHeight } : { minHeight: '6rem' }} className={`${expandedItemId == id ? '' : 'max-h-32 relative overflow-hidden transition ease-in-out delay-150 duration-300' }`}>
        {/* <div className={``}> */}
          {isHeadingIcon ?
            <div className="flex justify-start items-start">
              <div className="h-[4rem] w-[4rem] lg:h-[6.5rem] lg:w-[6.5rem] mr-[2rem]">
                <img src={isHeadingIcon} alt="" className="h-full w-full" />
              </div>
              <h2 className='text-[2.6rem] lg:text-[4rem] text-[#1E3365] font-[400] mb-[2rem]'>
                {title}
              </h2>
            </div>
            :
            <h2 className='text-[2.6rem] lg:text-[4rem] text-[#1E3365] font-[400] mb-[2rem] -mt-[.8rem]'>
              {title}
            </h2>
          }
          {content.map((data, i) => {
            return (
              <h2 className={`text-[1.8rem] lg:text-[2rem] text-[#000] sm:text-[#3E3E3E] font-[400] my-[.4rem]`}>
                {data}
              </h2>
            )
          })}
        </div>
        <button 
          className='flex items-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-[2rem] border border-blue-500 hover:border-transparent rounded-full mt-[1.6rem]'
          onClick={() => toggleExpand(id)}
        >
          {expandedItemId == id ?
            <><p className="text-[1.5rem]">Less</p><IoChevronUp className='ml-2' size={18}/></> 
            : 
            <><p className="text-[1.5rem]">Read more</p><IoChevronDown className='ml-2' size={18}/></>
          }
        </button>
      </div>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      PortfoliosSecCard();
    }, 2000);
  }, [imageHeight, expandedItemId]);

  return (
    <div 
      id={id} 
      className={`industries-page-card py-[4rem] w-100 ${isReverse ? "bg-[#E2EAFE]" : "bg-[#F7F7FF]"}`}
    >
      {PortfoliosSecCard()}
    </div>
  )
}

export default PortfoliosSec