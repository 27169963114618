import React, { useState } from 'react'
import { InnovativeSecurityProductsComponentCardImgs } from '../../../Static/constants/imgConstants/imgConstants'
import OfferingsInfoComponentCard from '../OfferingsInfoComponentCard/OfferingsInfoComponentCard'

function InnovativeSecurityProductsComponent() {
    const [data] = useState([
        {
            id: "ai-based-time-attendance",
            title: "Time & Attendance Solutions",
            content: "Effortlessly track attendance with advanced AI-powered solutions",
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg1,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIcon1,
        },
        {
            id: "high-level-document-security",
            title: "High Level Document Security",
            content: "Safeguard sensitive documents with top-tier security solutions.",
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg2,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIcon2,
        },
        {
            id: "smart-meeting",
            title: "Smart Meeting",
            content: "Optimise collaboration with intelligent Smart Meeting solutions.",
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg13,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIcon3,
        },
        {
            id: "visitor-management",
            title: "Visitor Management",
            content: "Streamline visitor experiences with efficient Visitor Management solutions.",
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg4,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIcon4,
        },
        {
            id: "q-management",
            title: "Q Management",
            content: "Enhance customer flow with advanced Queue Management systems.",
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg5,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIcon5,
        },
        {
            id: "passport-solution",
            title: "Passport Solution",
            content: "Unlock seamless passport solutions for streamlined travel experiences.",
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg6,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIcon6,
        },
        {
            id: "cheque-printing",
            title: "Cheque Printing",
            content: "Simplify cheque printing processes with efficient solutions.",
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg7,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIcon7,
        },
        {
            id: "enterprise-access-control",
            title: "Enterprise Access Control",
            content: "Empower enterprise security with advanced access control solutions.",
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg8,
            icon: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardIcon8,
        },
    ])

    return (
        <div className='offerings-info-component  py-[1.6rem] lg:py-[2.4rem] flex flex-col justify-center items-center w-full'>
            <div className="flex flex-col justify-center items-start container">
                <h1 className="text-[4rem] lg:text-[5rem] font-[500] text-[#00E0FF] uppercase">
                    Innovative Security Products
                </h1>
                <p className="text-[1.6rem] lg:text-[2.2rem] font-[400] text-white text-justify mt-[1rem] mb-[2.4rem] tracking-wider">
                    At SECURE WORLD, we offer a wide range of products and services to meet your IT needs. We provide expert care for networks, printers, systems, devices, applications, and your staff. We deliver enterprise-level, Fortune 500-quality IT infrastructure to small and medium-sized businesses at a price point you can afford. We do this by investing in tools and systems that deliver the solutions you need. This investment in people, process, and technology is shared across our system with thousands of our clients.
                </p>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-[.8rem] sm:gap-[2rem] [@media(min-width:1319px)]:gap-[4rem]">
                    {
                        data.map((items) => {
                            return (
                                <OfferingsInfoComponentCard
                                    id={items.id}
                                    title={items.title}
                                    content={items.content}
                                    bgImg={items.bgImg}
                                    icon={items.icon}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default InnovativeSecurityProductsComponent