import React from 'react'

function InputGroup({ label, isRequired, value, setValue, inputType, inputPlaceholder, isFocused, isContactUsAltBg }) {
    return (
        <div className='input-group flex flex-col'>
            <div className={`w-full ${inputPlaceholder === "Message" ? "h-[10rem]" : "h-[5.9rem]"} ${isContactUsAltBg ? "border-b-[#54BEFE] focus-within:border-b-[#130835]" : "border-b-[#54BEFE] border-b-[#54BEFE] focus-within:border-b-[#130835]"} border-b-[.3rem] rounded-[1rem]`}>
                <input 
                    className={`w-full h-full px-8 text-xl text-white placeholder-white ${isContactUsAltBg ? "bg-[#BECAF3]/[.21]" : "bg-[#FFFFFF]"}`}
                    type={inputType ? inputType : 'text'}
                    placeholder={inputPlaceholder}
                    autoFocus={isFocused}
                    value={value}
                    onChange={(e) => {
                        let nameReg = /[^a-z]/gi;
                        
                        console.log("label", label);
                        if(inputType === "number" && (e.target.value).length <= 10){
                            setValue(()=> e.target.value.replace(/[^0-9]/g, ""))
                        }else if(label === "Name" && !nameReg.test(e.target.value)){
                            setValue(e.target.value);  
                        }
                        else if(label === "Email"){
                            setValue(e.target.value);
                        }
                        else{
                            return
                        }
                        setValue(e.target.value);
                    }}
                />
            </div>
        </div>
    )
}

export default InputGroup
