import React from 'react'
import { useNavigate } from 'react-router-dom';
import { HomeLandingSectionIcons } from '../../../Static/constants/imgConstants/imgConstants'
import SectionHeaders from '../Headers/SectionHeaders'

function PageInfoFloating({ details }) {
    const navigate = useNavigate(); 

    return (
        <div className="relative my-[4rem]">
            <div className='page-info-floating container-lg flex flex-col justify-start items-start py-[2.4rem] px-[5rem] bg-[#FFFFFF] rounded-[3.6rem] shadow-[0_.4rem_6.4rem_0_rgba(22,60,96,0.20)] relative'>
                <SectionHeaders
                    contentPrimary="About "
                    contentSecondary="Us"
                    classes="text-[4rem] lg:text-[5rem] font-[500] text-[#008ED4] !mt-0"
                />
                <p className='text-[2rem] lg:text-[2.4rem] font-[400] text-[#404040] text-justify'>
                    {details}
                </p>
                <div className="mt-[5rem] text-base lg:text-[2rem] text-[#008ED4] flex justify-center items-center cursor-pointer" onClick={() => {navigate('/about-us')}}>
                    Read More
                    <div className="w-[2.7rem] ml-[1.5rem]">
                        <img src={HomeLandingSectionIcons.HomeLandingActionIconBlue} alt="" className="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageInfoFloating
