import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { HomeLandingSectionIcons } from '../../../Static/constants/imgConstants/imgConstants';
import { useNavigate } from 'react-router-dom';

const handleDragStart = (e) => e.preventDefault();

function HomeMainLadingSecComponent() {

    const navigate = useNavigate();

    const items = [
        <div onDragStart={handleDragStart} className='flex flex-col justify-center items-center mt-[17rem] md:mt-[10rem]'>
            <div className={`home-landing-sections w-full h-[80rem]`}>
                <div className='w-full my-auto h-full backdrop-blur-xl backdrop-opacity-100 bg-white/30 lg:bg-transparent lg:backdrop-filter-none'>
                    <div className='home-landing-sections-content flex flex-col justify-center items-start container-lg my-auto h-full'>
                        <h1 className='home-landing-sections-heading text-[5rem] sm:text-[7rem] text-[#008ED4] text-start'>
                            <pre className='font-[600] leading-[6rem] sm:leading-[10rem]'>
                                <span className="text-[#1E3365] font-[600]">
                                    {`Charting `}
                                </span>
                                {`\nTomorrow's Course`}
                            </pre>
                        </h1>
                        <h1 className='home-landing-sections-heading text-[2.4rem] sm:text-[3rem] text-[#3E3E3E] text-start font-[300] my-16'>
                            <pre className=''>
                                {`Fintech Excellence, Secure Solutions.\nAcknowledged Globally, Trusted Locally.\nInnovating the Future Securely.`}
                            </pre>
                        </h1>
                        <a
                            className='home-landing-sections-start-button text-2xl text-[#FFFF] mt-[4rem] w-[21.5rem] h-[6rem] flex justify-evenly items-center rounded-[3rem] cursor-pointer'
                            onClick={()=> navigate("/portfolios")}
                        >
                            Start Now
                            <div className='home-landing-sections-action-icon w-[2rem]'>
                                <img className='w-full h-full' src={HomeLandingSectionIcons.HomeLandingActionIcon} alt='icon' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>,
        <div onDragStart={handleDragStart} className='flex flex-col justify-center items-center mt-[17rem] md:mt-[10rem]'>
            <div className={`home-landing-sections-1 w-full h-[80rem]`}>
                <div className='w-full my-auto h-full backdrop-blur-xl backdrop-opacity-100 bg-white/30 lg:bg-transparent lg:backdrop-filter-none'>
                    <div className='home-landing-sections-content flex flex-col justify-center items-start container-lg my-auto h-full'>
                        <h1 className='home-landing-sections-heading text-[5rem] sm:text-[7rem] text-[#008ED4] text-start'>
                            <pre className='font-[600] leading-[6rem] sm:leading-[10rem]'>
                                {`Empowering the`}
                                <span className="text-[#1E3365] font-[600]">
                                    {`\nFuture`}
                                </span>
                            </pre>
                        </h1>
                        <h1 className='home-landing-sections-heading text-[2.4rem] sm:text-[3rem] text-[#3E3E3E] text-start font-[300] my-16'>
                            <pre className=''>
                                {`Effortless Digital Payments, Seamless\nCurrency Exchange, Across Borders`}
                            </pre>
                        </h1>
                        <a
                            className='home-landing-sections-start-button text-2xl text-[#FFFF] mt-[4rem] w-[21.5rem] h-[6rem] flex justify-evenly items-center rounded-[3rem] cursor-pointer'
                            onClick={()=> navigate("/fintech-solutions")}
                        >
                            Start Now
                            <div className='home-landing-sections-action-icon w-[2rem]'>
                                <img className='w-full h-full' src={HomeLandingSectionIcons.HomeLandingActionIcon} alt='icon' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>,
        <div onDragStart={handleDragStart} className='flex flex-col justify-center items-center mt-[17rem] md:mt-[10rem]'>
            <div className={`home-landing-sections-2 w-full h-[80rem]`}>
                <div className='w-full my-auto h-full backdrop-blur-xl backdrop-opacity-100 bg-white/30 lg:bg-transparent lg:backdrop-filter-none'>
                    <div className='home-landing-sections-content flex flex-col justify-center items-start container-lg my-auto h-full'>
                        <h1 className='home-landing-sections-heading text-[5rem] sm:text-[7rem] text-[#008ED4] text-start'>
                            <pre className='font-[600] leading-[6rem] sm:leading-[10rem]'>
                                {`Navigating`}
                                <span className="text-[#1E3365] font-[600]">
                                    {`\nExcellence`}
                                </span>
                            </pre>
                        </h1>
                        <h1 className='home-landing-sections-heading text-[2.4rem] sm:text-[3rem] text-[#3E3E3E] text-start font-[300] my-16'>
                            <pre className=''>
                                {`Our Journey, Your Trust. Crafting Tomorrow's\nSolutions with Commitment, Innovation, and a\nVision for Progress`}
                            </pre>
                        </h1>
                        <a
                            className='home-landing-sections-start-button text-2xl text-[#FFFF] mt-[4rem] w-[21.5rem] h-[6rem] flex justify-evenly items-center rounded-[3rem] cursor-pointer'
                            onClick={()=> navigate("/about-us")}
                        >
                            Start Now
                            <div className='home-landing-sections-action-icon w-[2rem]'>
                                <img className='w-full h-full' src={HomeLandingSectionIcons.HomeLandingActionIcon} alt='icon' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>,
        <div onDragStart={handleDragStart} className='flex flex-col justify-center items-center mt-[17rem] md:mt-[10rem]'>
            <div className={`home-landing-sections-3 w-full h-[80rem]`}>
                <div className='w-full my-auto h-full backdrop-blur-xl backdrop-opacity-100 bg-white/30 lg:bg-transparent lg:backdrop-filter-none'>
                    <div className='home-landing-sections-content flex flex-col justify-center items-start container-lg my-auto h-full'>
                        <h1 className='home-landing-sections-heading text-[5rem] sm:text-[7rem] text-[#008ED4] text-start'>
                            <pre className='font-[600] leading-[6rem] sm:leading-[10rem]'>
                                {`Revolutionizing `}
                                <span className="text-[#1E3365] font-[600]">
                                    {`\nSecurity`}
                                </span>
                            </pre>
                        </h1>
                        <h1 className='home-landing-sections-heading text-[2.4rem] sm:text-[3rem] text-[#3E3E3E] text-start font-[300] my-16'>
                            <pre className=''>
                                {`Innovative Solutions for a Safer Tomorrow.\nRedefining Security Standards with\nVisionary Technology.`}
                            </pre>
                        </h1>
                        <a
                            className='home-landing-sections-start-button text-2xl text-[#FFFF] mt-[4rem] w-[21.5rem] h-[6rem] flex justify-evenly items-center rounded-[3rem] cursor-pointer'
                            onClick={()=> navigate("/innovative-security-products")}
                        >
                            Start Now
                            <div className='home-landing-sections-action-icon w-[2rem]'>
                                <img className='w-full h-full' src={HomeLandingSectionIcons.HomeLandingActionIcon} alt='icon' />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>,
    ]


    return (
        <div className='home-main-lading-sec-component my-[-6rem]'>
            <AliceCarousel autoPlayInterval={4000} infinite autoPlayStrategy='default' autoPlay mouseTracking items={items} keyboardNavigation={true} />
        </div>
    )
}

export default HomeMainLadingSecComponent