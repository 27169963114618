export const HomeLandingSectionIcons = {
    HomeLandingActionIcon: require("../../assets/icon/home-landing-action-icon.png"),
    HomeLandingLeftBackground: require("../../assets/img/home-landing-left-background.png"),
    HomeLandingActionIconBlue: require("../../assets/icon/home-landing-action-icon-blue.png"),
}

export const NavbarImgs = {
    websiteLogo: require("../../assets/img/website-logo.png")
}

export const InfominezBannerImgs = {
    websiteLogo: require("../../assets/img/infominez-logo.png")
}

export const AboutUsImg = {
    readMoreIcon: require("../../assets/icon/read-more-icon-pink.png")
}

export const ServicesIcons = {
    serviceIcon1: require("../../assets/icon/services-icon-1.png"),
    serviceIcon2: require("../../assets/icon/services-icon-2.png"),
    serviceIcon3: require("../../assets/icon/services-icon-3.png"),
    serviceIcon4: require("../../assets/icon/services-icon-4.png"),
    serviceIcon5: require("../../assets/icon/services-icon-5.png"),
    serviceIcon6: require("../../assets/icon/services-icon-6.png"),
    serviceIcon7: require("../../assets/icon/services-icon-7.png"),
    serviceIcon8: require("../../assets/icon/services-icon-8.png"),
}

export const TestimonyCardImgs = {
    QuotationIcon: require("../../assets/icon/testimony-icon.png"),
    OrganizationLogo: require("../../assets/img/testimony-organization-logo.png"),
    testimonyClientImg: require("../../assets/img/testimony-client-img.png"),
}

export const PortFolioImgs = {
    PortfolioImg1: require("../../assets/icon/portfolio-img1.png"),
    PortfolioImg2: require("../../assets/icon/portfolio-img2.png"),
    PortfolioImg3: require("../../assets/icon/portfolio-img3.png"),
    PortfolioImg4: require("../../assets/icon/portfolio-img4.png"),
    PortfolioImgLight1: require("../../assets/icon/portfolio-img-light1.png"),
    PortfolioImgLight2: require("../../assets/icon/portfolio-img-light2.png"),
    PortfolioImgLight3: require("../../assets/icon/portfolio-img-light3.png"),
    PortfolioImgLight4: require("../../assets/icon/portfolio-img-light4.png"),
}

export const ProductPageImgs = {
    LandingSectionImg: require("../../assets/img/services-page-img.jpg")
}

export const ServicePageCardImg = {
    productImg1: require("../../assets/img/product-img-1.png"),
    productImg2: require("../../assets/img/product-img-2.png"),
    productImg3: require("../../assets/img/product-img-3.png"),
    productImg4: require("../../assets/img/product-img-4.png"),
    productImg5: require("../../assets/img/product-img-5.png"),
    productImg6: require("../../assets/img/product-img-6.png"),
}

export const ServicesPageComponentImgs = {
    servicesImg1: require("../../assets/img/services-img1.png"),
    servicesImg2: require("../../assets/img/services-img2.png"),
    servicesImg3: require("../../assets/img/services-img3.png"),
    servicesImg4: require("../../assets/img/services-img4.png"),
    servicesImg5: require("../../assets/img/services-img5.png"),
    servicesImg6: require("../../assets/img/services-img6.png"),
    servicesImg7: require("../../assets/img/services-img7.png"),
    servicesImg8: require("../../assets/img/services-img8.png"),
    servicesImg9: require("../../assets/img/services-img9.png"),
}

export const PrestigiousClientsImgs = {
    PrestigiousClientsImg1: require("../../assets/img/Prestigious-clients-img1.png"),
    PrestigiousClientsImg2: require("../../assets/img/Prestigious-clients-img2.png"),
    PrestigiousClientsImg3: require("../../assets/img/Prestigious-clients-img3.png"),
    PrestigiousClientsImg4: require("../../assets/img/Prestigious-clients-img4.png"),
    PrestigiousClientsImg5: require("../../assets/img/Prestigious-clients-img5.png"),
    PrestigiousClientsImg6: require("../../assets/img/Prestigious-clients-img6.png"),
    PrestigiousClientsImg7: require("../../assets/img/Prestigious-clients-img7.png"),
    PrestigiousClientsImg8: require("../../assets/img/Prestigious-clients-img8.png"),
    PrestigiousClientsImg9: require("../../assets/img/Prestigious-clients-img9.png"),
}

export const LatestInsightImgs = {
    LatestInsightsImg1: require("../../assets/img/latest-insights-img-1.png"),
    LatestInsightsImg2: require("../../assets/img/latest-insights-img-2.png"),
    LatestInsightsImg3: require("../../assets/img/latest-insights-img-3.png"),
    LatestInsightsImg4: require("../../assets/img/latest-insights-img-4.png"),
    LatestInsightsImg5: require("../../assets/img/latest-insights-img-5.png"),
    LatestInsightsBgImg: require("../../assets/img/latest-insights-bg-img.png"),
}

export const ReadMoreIcons = {
    ReadMoreIcon: require("../../assets/icon/read-more-icon-blue.png")
}

export const socialIcons = {
    SocialIcon1: require("../../assets/icon/social-icon1.png"),
    SocialIcon2: require("../../assets/icon/social-icon2.png"),
    SocialIcon3: require("../../assets/icon/social-icon3.png"),
    SocialIcon4: require("../../assets/icon/social-icon4.png"),
    ContactIcon1: require("../../assets/icon/contact-icon-1.png"),
    ContactIcon2: require("../../assets/icon/contact-icon-2.png"),
    ContactIcon3: require("../../assets/icon/contact-icon-3.png"),
    ContactIcon4: require("../../assets/icon/contact-icon-4.png"),
    ContactIcon5: require("../../assets/icon/contact-icon-5.png"),
    ContactIcon6: require("../../assets/icon/contact-icon-6.png"),
}

export const ourOfferingsIcons = {
    OfferingIconBlue1: require("../../assets/icon/offering-icon-blue-1.png"),
    OfferingIconBlue2: require("../../assets/icon/offering-icon-blue-2.png"),
}

export const PortfolioImgs = {
    portfolioImg1: require("../../assets/img/portfolio-img1.jpg"),
    portfolioImg2: require("../../assets/img/portfolio-img2.jpg"),
    portfolioImg3: require("../../assets/img/portfolio-img3.jpg"),
    portfolioImg4: require("../../assets/img/portfolio-img4.jpg"),
    portfolioImg5: require("../../assets/img/portfolio-img5.jpg"),
    portfolioImg6: require("../../assets/img/portfolio-img6.jpg"),
    portfolioImg7: require("../../assets/img/portfolio-img7.jpg"),
    portfolioImg8: require("../../assets/img/portfolio-img8.jpg"),
}

export const OfferingsInfoComponentCardImgs = {
    OfferingsInfoComponentCardImg1: require("../../assets/img/OfferingsInfoComponentCard-img1.jpg"),
    OfferingsInfoComponentCardImg2: require("../../assets/img/OfferingsInfoComponentCard-img2.jpg"),
    OfferingsInfoComponentCardImg3: require("../../assets/img/OfferingsInfoComponentCard-img3.jpg"),
    OfferingsInfoComponentCardImg4: require("../../assets/img/OfferingsInfoComponentCard-img4.jpg"),

    OfferingsInfoComponentCardIcon1: require("../../assets/icon/offerings-info-component-cardIcon1.jpg"),
    OfferingsInfoComponentCardIcon2: require("../../assets/icon/offerings-info-component-cardIcon2.jpg"),
    OfferingsInfoComponentCardIcon3: require("../../assets/icon/offerings-info-component-cardIcon3.jpg"),
    OfferingsInfoComponentCardIcon4: require("../../assets/icon/offerings-info-component-cardIcon4.jpg"),

    OfferingsInfoComponentCardIconDark1: require("../../assets/icon/offerings-info-component-cardIcon-dark1.png"),
    OfferingsInfoComponentCardIconDark2: require("../../assets/icon/offerings-info-component-cardIcon-dark2.png"),
    OfferingsInfoComponentCardIconDark3: require("../../assets/icon/offerings-info-component-cardIcon-dark3.png"),
    OfferingsInfoComponentCardIconDark4: require("../../assets/icon/offerings-info-component-cardIcon-dark4.png"),
}

export const InnovativeSecurityProductsComponentCardImgs = {
    InnovativeSecurityProductsComponentCardIcon1: require("../../assets/icon/ispc-icon1.jpg"),
    InnovativeSecurityProductsComponentCardIcon2: require("../../assets/icon/ispc-icon2.jpg"),
    InnovativeSecurityProductsComponentCardIcon3: require("../../assets/icon/ispc-icon3.jpg"),
    InnovativeSecurityProductsComponentCardIcon4: require("../../assets/icon/ispc-icon4.jpg"),
    InnovativeSecurityProductsComponentCardIcon5: require("../../assets/icon/ispc-icon5.jpg"),
    InnovativeSecurityProductsComponentCardIcon6: require("../../assets/icon/ispc-icon6.jpg"),
    InnovativeSecurityProductsComponentCardIcon7: require("../../assets/icon/ispc-icon7.jpg"),
    InnovativeSecurityProductsComponentCardIcon8: require("../../assets/icon/ispc-icon8.jpg"),


    InnovativeSecurityProductsComponentCardIconDark1: require("../../assets/icon/ispc-icon-dark1.png"),
    InnovativeSecurityProductsComponentCardIconDark2: require("../../assets/icon/ispc-icon-dark2.png"),
    InnovativeSecurityProductsComponentCardIconDark3: require("../../assets/icon/ispc-icon-dark3.png"),
    InnovativeSecurityProductsComponentCardIconDark4: require("../../assets/icon/ispc-icon-dark4.png"),
    InnovativeSecurityProductsComponentCardIconDark5: require("../../assets/icon/ispc-icon-dark5.png"),
    InnovativeSecurityProductsComponentCardIconDark6: require("../../assets/icon/ispc-icon-dark6.png"),
    InnovativeSecurityProductsComponentCardIconDark7: require("../../assets/icon/ispc-icon-dark7.png"),
    InnovativeSecurityProductsComponentCardIconDark8: require("../../assets/icon/ispc-icon-dark8.png"),

    InnovativeSecurityProductsComponentCardImg1: require("../../assets/img/ispc-img1.jpg"),
    InnovativeSecurityProductsComponentCardImg2: require("../../assets/img/ispc-img2.png"),
    InnovativeSecurityProductsComponentCardImg3: require("../../assets/img/ispc-img3.png"),
    InnovativeSecurityProductsComponentCardImg4: require("../../assets/img/ispc-img4.png"),
    InnovativeSecurityProductsComponentCardImg5: require("../../assets/img/ispc-img5.jpg"),
    InnovativeSecurityProductsComponentCardImg6: require("../../assets/img/ispc-img6.jpg"),
    InnovativeSecurityProductsComponentCardImg7: require("../../assets/img/ispc-img7.jpg"),
    InnovativeSecurityProductsComponentCardImg8: require("../../assets/img/ispc-img8.jpg"),
    InnovativeSecurityProductsComponentCardImg9: require("../../assets/img/ispc-img9.jpg"),
    InnovativeSecurityProductsComponentCardImg10: require("../../assets/img/ispc-img10.png"),
    InnovativeSecurityProductsComponentCardImg11: require("../../assets/img/ispc-img11.jpg"),
    InnovativeSecurityProductsComponentCardImg12: require("../../assets/img/ispc-img12.jpg"),
    InnovativeSecurityProductsComponentCardImg13: require("../../assets/img/ispc-img13.jpg"),
    InnovativeSecurityProductsComponentCardImgOther1: require("../../assets/img/ispc-img-other-1.jpg"),
    InnovativeSecurityProductsComponentCardImgOther2: require("../../assets/img/ispc-img-other-2.png"),

    
}

export const ProductImgs = {
    ProductImg1: require("../../assets/img/products-img-1.jpg"),
    ProductImg2: require("../../assets/img/products-img-2.jpg"),
    ProductImg3: require("../../assets/img/products-img-3.jpg"),
    ProductImg6: require("../../assets/img/products-img-6.png"),
    ProductImg7: require("../../assets/img/products-img-7.jpg"),
    ProductImg4: require("../../assets/img/products-img-4.jpg"),
    ProductImg5: require("../../assets/img/products-img-5.png"),
}