import React from 'react'

function FooterMini() {
    return (
        <div className='footer-mini flex justify-center items-center w-full h-[4.4rem] bg-[#3EBAF6]'>
            <p className='text-center text-base font-[500] text-[#FFFFFF] tracking-wider text-Montserrat-sans-serif'>
                ©2022 SecureWorld All Rights Reserved.
            </p>
        </div>
    )
}

export default FooterMini
