import React from 'react'
import PrimaryLayout from '../../Components/common/layout/PrimaryLayout'
import HomeLandingSections from '../../Components/common/HomeLandingSections/HomeLandingSections'
import ServicesSec from '../../Components/main/ServicesSec/ServicesSec'
import LatestInsights from '../../Components/main/LatestInsights/LatestInsights'
import PrestigiousClients from '../../Components/main/PrestigiousClients/PrestigiousClients'
import InfominezBanner from '../../Components/common/InfominezBanner/infominezBanner'
import ClientTestimonial from '../../Components/main/ClientTestimonial/ClientTestimonial'
import AboutUsComponent from '../../Components/main/AboutUsComponent/AboutUsComponent'

function AboutUs({ setSelectedPortfolio, setSelectedCard, setSelectedProduct }) {

    return (
        <div className='about-us'>
            <PrimaryLayout
                showNavbar={true}
                showContactUs={true}
                showFooterMain={true}
                showFooterMini={true}
                isContactUsAltBg={false}
                setSelectedPortfolio={setSelectedPortfolio}
                setSelectedCard={setSelectedCard}
                setSelectedProduct={setSelectedProduct}
            >
                <HomeLandingSections
                    title1={`Navigating`}
                    title2={`\nExcellence`}
                    subtitle={`Our Journey, Your Trust. Crafting Tomorrow's\nSolutions with Commitment, Innovation, and a\nVision for Progress`}
                    route={"about-us"}
                />
                <AboutUsComponent />
                <ServicesSec isServicesAlt={false} />
                <InfominezBanner />
                <ClientTestimonial />
                <PrestigiousClients />
                <LatestInsights />
            </PrimaryLayout>
        </div>
    )
}

export default AboutUs