import React, { useState, useEffect } from 'react';
import { LatestInsightImgs } from '../../../Static/constants/imgConstants/imgConstants';
import SectionHeaders from '../../common/Headers/SectionHeaders'
import LatestInsightsCard from '../../common/LatestInsightsCard/LatestInsightsCard';
import LatestInsightsNavbar from '../LatestInsightsNavbar/LatestInsightsNavbar';

const handleDragStart = (e) => e.preventDefault();

function LatestInsights() {
    const [selectedFlag, setSelectedFlag] = useState('All')
    const [selectedFlagData, setSelectedFlagData] = useState()

    const items1 = [
        { bgImg: LatestInsightImgs.LatestInsightsImg4 },
        { bgImg: LatestInsightImgs.LatestInsightsImg5 },
        /* { bgImg: LatestInsightImgs.LatestInsightsImg3 },
        { bgImg: LatestInsightImgs.LatestInsightsImg3 },
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
        { bgImg: LatestInsightImgs.LatestInsightsImg1 }, */
    ];

    const items2 = [
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
        { bgImg: LatestInsightImgs.LatestInsightsImg1 },
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
        { bgImg: LatestInsightImgs.LatestInsightsImg3 },
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
        { bgImg: LatestInsightImgs.LatestInsightsImg3 },
    ];

    const items3 = [
        { bgImg: LatestInsightImgs.LatestInsightsImg1 },
        { bgImg: LatestInsightImgs.LatestInsightsImg3 },
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
    ];

    const items4 = [
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
        { bgImg: LatestInsightImgs.LatestInsightsImg1 },
        { bgImg: LatestInsightImgs.LatestInsightsImg3 },
        { bgImg: LatestInsightImgs.LatestInsightsImg1 },
    ];

    const items5 = [
        { bgImg: LatestInsightImgs.LatestInsightsImg3 },
        { bgImg: LatestInsightImgs.LatestInsightsImg1 },
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
        { bgImg: LatestInsightImgs.LatestInsightsImg1 },
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
    ];

    const items6 = [
        { bgImg: LatestInsightImgs.LatestInsightsImg3 },
        { bgImg: LatestInsightImgs.LatestInsightsImg2 },
    ];

    useEffect(() => {
        if (selectedFlag === "All") {
            setSelectedFlagData(items1)
        } else if (selectedFlag === "Blog") {
            setSelectedFlagData(items2)
        } else if (selectedFlag === "Whitepaper") {
            setSelectedFlagData(items3)
        } else if (selectedFlag === "Newsletter") {
            setSelectedFlagData(items4)
        } else if (selectedFlag === "Podcast") {
            setSelectedFlagData(items5)
        } else if (selectedFlag === "News Event") {
            setSelectedFlagData(items6)
        }
    }, [selectedFlag]);

    return (
        <div onDragStart={handleDragStart} className='latest-insights relative bg-[#FFFFFF]'>
            <div className='contact-us-container flex flex-col justify-start items-start container-lg z-10 '>
                <SectionHeaders
                    contentPrimary="Latest "
                    contentSecondary="Insights"
                    classes="text-[4rem] lg:text-[5rem] font-[700] text-[#008ED4] !mt-[2rem]"
                    secondaryTextClass={true}
                />
                <LatestInsightsNavbar selectedFlag={selectedFlag} setSelectedFlag={setSelectedFlag} />
                <div className='w-full my-[6.5rem]'>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4">
                        {selectedFlag === "All" ? 
                            selectedFlagData?.map((data) => {
                                return (
                                   <LatestInsightsCard bgImg={data.bgImg} />
                               )}
                             )
                            :selectedFlag === "Blog" ?
                            <div className="flex justify-center items-center h-[50%] w-[100%]">
                                <h1 className="text-[3rem]">
                                    No New Blog Posted...
                                </h1>
                            </div>
                            :selectedFlag === "Whitepaper" ?
                            <div className="flex justify-center items-center h-[50%] w-[100%]">
                                <h1 className="text-[3rem]">
                                    No New White Papers Posted...
                                </h1>
                            </div>
                            :selectedFlag === "Newsletter" ?
                            <div className="flex justify-center items-center h-[50%] w-[100%]">
                                <h1 className="text-[3rem]">
                                    No New News letter Posted...
                                </h1>
                            </div>
                            :selectedFlag === "Podcast" ?
                            <div className="flex justify-center items-center h-[50%] w-[100%]">
                                <h1 className="text-[3rem]">
                                    No New Podcast Posted...
                                </h1>
                            </div>
                            :
                            <div className="flex justify-center items-center h-[50%] w-[100%]">
                                <h1 className="text-[3rem]">
                                    No News Event Posted...
                                </h1>
                            </div>
                        }
                    </div>
                </div>
                <div className="bg-[#008ED4] h-[16rem] sm:h-[22rem] lg:h-[26.3rem] container-lg mt-[8.6rem] flex justify-evenly items-center text-white">
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[3rem] sm:text-[4rem] lg:text-[5rem] [@media(min-width:1300px)]:text-[5.6rem]">4000</p>
                        <p className="text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] lg:text-[2.8rem]">Happy Clients</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[3rem] sm:text-[4rem] lg:text-[5rem] [@media(min-width:1300px)]:text-[5.6rem]">2 Million</p>
                        <p className="text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] lg:text-[2.8rem]">Hardware Delivered</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[3rem] sm:text-[4rem] lg:text-[5rem] [@media(min-width:1300px)]:text-[5.6rem]">12</p>
                        <p className="text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] lg:text-[2.8rem]">Winning Awards</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-[3rem] sm:text-[4rem] lg:text-[5rem] [@media(min-width:1300px)]:text-[5.6rem]">24/7</p>
                        <p className="text-[1.6rem] sm:text-[1.8rem] lg:text-[2rem] lg:text-[2.8rem]">Fast Support</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestInsights
