import React from 'react'
import { useNavigate } from 'react-router-dom';
import { NavbarImgs, socialIcons } from '../../../Static/constants/imgConstants/imgConstants';

function FooterMain({ setSelectedCard, setSelectedProduct, setSelectedPortfolio }) {

    const navigate = useNavigate();

    const handleClick = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className='footer-main bg-[#130835]'>
            <div className='flex flex-col lg:flex-row justify-center items-center container-lg py-[8rem]'>
                <div className='w-[50%] lg:w-[30%] h-full flex flex-col justify-start items-start lg:pt-[4rem]'>
                    <div
                        className='footer-infominez-logo w-[30rem] mt-4 mb-10 cursor-pointer'
                        onClick={() => { navigate("/") }}
                    >
                        <img
                            className='w-full'
                            src={NavbarImgs.websiteLogo}
                            alt='logo'
                        />
                    </div>
                    <p className='text-left text-[1.4rem] text-[#FFFFFF] text-[500] text-Montserrat-sans-serif sm:pr-[4rem]'>
                        {`Have a question or comment about Our\nProducts & Services ? You can reach us by chat,\nphone or the email below.`}
                    </p>
                </div>
                <div className='w-[100%] lg:w-[70%] h-full flex justify-between items-start pt-[4rem] text-Montserrat-sans-serif'>
                    <div className='flex flex-row flex-wrap items-start justify-between w-[100%] '>
                        <div className='flex flex-col w-[50%] sm:w-[25%] truncate pr-[1rem] justify-center items-start text-sm'>
                            <h5 className=' text-left text-[#12D4FF] mb-6'>
                                Products
                            </h5>
                            <a
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={(e) => { e.preventDefault(); navigate("/products"); setSelectedProduct('id-card-printer') }}
                                data-te-toggle="tooltip"
                                title="ID Card Printer"
                            >
                                ID Card Printer
                            </a>
                            <a
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={(e) => { e.preventDefault(); navigate("/products"); setSelectedProduct('time-terminal') }}
                                data-te-toggle="tooltip"
                                title="Time Terminal"
                            >
                                Time Terminal
                            </a>
                            <a
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={(e) => { e.preventDefault(); navigate("/products"); setSelectedProduct('access-control') }}
                                data-te-toggle="tooltip"
                                title="Access Control"
                            >
                                Access Control
                            </a>
                            <a
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={(e) => { e.preventDefault(); navigate("/products"); setSelectedProduct('smart-card-reader') }}
                                data-te-toggle="tooltip"
                                title="Smart card & Reader"
                            >
                                Smart card & Reader
                            </a>
                            <a
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={(e) => { e.preventDefault(); navigate("/products"); setSelectedProduct('turnstile') }}
                                data-te-toggle="tooltip"
                                title="Turnstile"
                            >
                                Turnstile
                            </a>
                            <a
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={(e) => { e.preventDefault(); navigate("/products"); setSelectedProduct('Gemalto-passport') }}
                                data-te-toggle="tooltip"
                                title="Gemalto 3M passport"
                            >
                                Gemalto 3M passport
                            </a>
                            <a
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3'
                                onClick={(e) => { e.preventDefault(); navigate("/products"); setSelectedProduct('digital-kiosk') }}
                                data-te-toggle="tooltip"
                                title="Digital Kiosk"
                            >
                                Digital Kiosk
                            </a>
                        </div>
                        <div className='flex flex-col w-[50%] sm:w-[25%] truncate pr-[1rem] justify-center items-start text-sm'>
                            <h5 className=' text-left text-[#12D4FF] mb-6'>
                                Services
                            </h5>
                            <a 
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' 
                                onClick={(e) => {
                                    e.preventDefault(); navigate("/innovative-security-products");
                                    setSelectedPortfolio('ai-based-time-attendance')
                                }}
                                data-te-toggle="tooltip"
                                title="AI based Time Attendance"
                            >
                                AI based Time Attendance
                            </a>
                            <a 
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' 
                                onClick={(e) => {
                                    e.preventDefault(); navigate("/innovative-security-products");
                                    setSelectedPortfolio('high-level-document-security')
                                }}
                                data-te-toggle="tooltip"
                                title="High level Document Security"
                            >
                                High level Document Security
                            </a>
                            <a 
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' 
                                onClick={(e) => {
                                    e.preventDefault(); navigate("/innovative-security-products");
                                    setSelectedPortfolio('smart-meeting')
                                }}
                                data-te-toggle="tooltip"
                                title="Smart Meeting"
                            >
                                Smart Meeting
                            </a>
                            <a 
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' 
                                onClick={(e) => {
                                    e.preventDefault(); navigate("/innovative-security-products");
                                    setSelectedPortfolio('visitor-management')
                                }}
                                data-te-toggle="tooltip"
                                title="Visitor Management"
                            >
                                Visitor Management
                            </a>
                            <a 
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' 
                                onClick={(e) => {
                                    e.preventDefault(); navigate("/innovative-security-products");
                                    setSelectedPortfolio('q-management')
                                }}
                                data-te-toggle="tooltip"
                                title="Q management"
                            >
                                Q management
                            </a>
                            <a 
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' 
                                onClick={(e) => {
                                    e.preventDefault(); navigate("/innovative-security-products");
                                    setSelectedPortfolio('passport-solution')
                                }}
                                data-te-toggle="tooltip"
                                title="Passport solution"
                            >
                                Passport solution
                            </a>
                            <a 
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' 
                                onClick={(e) => {
                                    e.preventDefault(); navigate("/innovative-security-products");
                                    setSelectedPortfolio('cheque-printing')
                                }}
                                data-te-toggle="tooltip"
                                title="Cheque Printing"
                            >
                                Cheque Printing
                            </a>
                            <a 
                                className='uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' 
                                onClick={(e) => {
                                    e.preventDefault(); navigate("/innovative-security-products");
                                    setSelectedPortfolio('enterprise-access-control')
                                }}
                                data-te-toggle="tooltip"
                                title="Enterprise Access Control"
                            >
                                Enterprise Access Control
                            </a>
                        </div>
                        <div className='flex flex-col w-[50%] sm:w-[25%] truncate pr-[1rem] justify-center items-start text-sm sm:pl-[1.2rem]'>
                            <h5 className=' text-left text-[#12D4FF] mb-6'>
                                Social
                            </h5>
                            <div className='flex uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] my-3'
                                onClick={() => handleClick('https://www.linkedin.com/company/secureworld')}
                            >
                                <div className='social-icons w-[2.1rem] h-[2rem] mr-8'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.SocialIcon1}
                                        alt='linkedin'
                                    />
                                </div>
                                Linkedin
                            </div>
                            <div className='flex uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] my-3'
                                onClick={() => handleClick('https://www.facebook.com/secureworld')}
                            >
                                <div className='social-icons w-[2.1rem] h-[2rem] mr-8'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.SocialIcon2}
                                        alt='facebook'
                                    />
                                </div>
                                Facebook
                            </div>
                            <div className='flex uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] my-3'
                                onClick={() => handleClick('https://twitter.com/secureworld')}
                            >
                                <div className='social-icons w-[2.1rem] h-[2rem] mr-8'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.SocialIcon3}
                                        alt='twitter'
                                    />
                                </div>
                                Twitter
                            </div>
                            <div className='flex uppercase cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] my-3'
                                onClick={() => handleClick('https://instagram.com')}
                            >
                                <div className='social-icons w-[2.1rem] h-[2rem] mr-8'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.SocialIcon4}
                                        alt='insta'
                                    />
                                </div>
                                Instagram
                            </div>
                        </div>
                        <div className='flex flex-col w-[50%] sm:w-[25%] truncate pr-[1rem] justify-center items-start text-sm'>
                            <h5 className=' text-left text-[#12D4FF] mb-6'>
                                Contact
                            </h5>
                            <div className='flex items-center cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' data-te-toggle="tooltip" title="P.O.Box - 376281, Dubai, UAE" onClick={() => {window.open("https://maps.google.com?q="+25.2048+","+55.2708 );}}>
                                <div className='social-icons w-[.95rem] h-[1.4rem] mr-[1.7rem]'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.ContactIcon2}
                                        alt='icon'
                                    />
                                </div>
                                P.O.Box - 376281, Dubai, UAE
                            </div>
                            <div className='flex items-center cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' data-te-toggle="tooltip" title="Office - +971 429 46202">
                                <div className='social-icons w-[.95rem] h-[1.4rem] mr-[1.7rem]'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.ContactIcon1}
                                        alt='icon'
                                    />
                                </div>
                                Office - +971 429 46202
                            </div>
                            <div className='flex items-center cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' data-te-toggle="tooltip" title="Sales - +971 555 969736">
                                <div className='social-icons w-[1.8rem] mr-[1rem]'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.ContactIcon3}
                                        alt='icon'
                                    />
                                </div>
                                Sales - +971 555 969736
                            </div>
                            <div className='flex items-center cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' data-te-toggle="tooltip" title="Project - +971 555 651732">
                                <div className='social-icons w-[1.8rem] mr-[1rem]'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.ContactIcon4}
                                        alt='icon'
                                    />
                                </div>
                                Project - +971 555 651732
                            </div>
                            <div className='flex items-center cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' data-te-toggle="tooltip" title="info@secureworldme.com">
                                <div className='social-icons w-[1.8rem] h-[1.1rem] mr-[1rem]'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.ContactIcon5}
                                        alt='icon'
                                    />
                                </div>
                                info@secureworldme.com
                            </div>
                            <a href='https://secureworldme.com/' className='flex items-center cursor-pointer text-left text-[#FFFFFF] hover:text-[#12D4FF] mb-3' data-te-toggle="tooltip" title="www.secureworldme.com">
                                <div className='social-icons w-[1.8rem] mr-[1rem]'>
                                    <img
                                        className='w-full h-full'
                                        src={socialIcons.ContactIcon6}
                                        alt='icon'
                                    />
                                </div>
                                www.secureworldme.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterMain
