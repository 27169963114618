import React, { useState } from 'react'
import OfferingsInfoComponentCard from '../../common/OfferingsInfoComponentCard/OfferingsInfoComponentCard'
import { OfferingsInfoComponentCardImgs } from "../../../Static/constants/imgConstants/imgConstants"

function OfferingsInfoComponent() {

  const [data ]= useState([
    {
      id: "atm-ims",
      title: "ATM IMS",
      content: "ATM Incident Management System",
      bgImg: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardImg1,
      icon: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardIcon1,
    },
    {
      id: "digital-payment-platform",
      title: "Digital Payment Platform",
      content: "Empowering your digital world with seamless payments",
      bgImg: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardImg2,
      icon: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardIcon2,
    },
    {
      id: "smart-saving-platform",
      title: "Smart Saving Platform",
      content: "Smart Saving, Smarter Future – Your path to financial success.",
      bgImg: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardImg3,
      icon: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardIcon3,
    },
    {
      id: "door-pay",
      title: "Door Pay",
      content: "Banking Convenience Delivered to Your Doorstep.",
      bgImg: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardImg4,
      icon: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardIcon4,
    }
  ])

  return (
    <div className='offerings-info-component py-[1.6rem] lg:py-[2.4rem] flex flex-col justify-center items-center w-full'>
      <div className="flex flex-col justify-center items-start container-lg">
        <h1 className="text-[4rem] lg:text-[5rem] font-[500] text-[#00E0FF] uppercase">
          Fintech Solutions
        </h1>
        <p className="text-[1.6rem] lg:text-[2.2rem] font-[400] text-white text-justify mt-[1rem] mb-[2.4rem] tracking-wider">
          Secure World, a name synonymous with security excellence for the past 15 years in the UAE, is excited to introduce a new chapter in our story. We are committed to our mission of making finance accessible, secure, and efficient for every individual and business in the UAE.In a world where technology shapes the future of finance, Secure World is proud to embark on its new journey into the Fintech market of the UAE. With a successful history in smart security systems, we're ready to bring innovation, trust, and a commitment to excellence to the financial sector.
        </p>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-[.8rem] sm:gap-[2rem] [@media(min-width:1319px)]:gap-[4rem]">
          {
            data.map((items) => {
              return (
                <OfferingsInfoComponentCard
                  id={items.id}
                  title={items.title}
                  content={items.content}
                  bgImg={items.bgImg}
                  icon={items.icon}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default OfferingsInfoComponent