import React from 'react'

function PrestigiousClientsCard({ mainIcon }) {
    return (
        <div className={`industries-sec-card cursor-pointer flex flex-col justify-start items-start my-[3.2rem]`}>
            <div className='services-card w-[24rem] h-[24rem]'>
                <img
                    className='w-full h-full'
                    src={mainIcon}
                    alt='img'
                />
            </div>
        </div>
    )
}

export default PrestigiousClientsCard