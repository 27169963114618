import React from 'react'

function LatestInsightsNavbar({ selectedFlag, setSelectedFlag }) {
    return (
        <div className='latest-insights-navbar flex justify-start items-start container-lg overflow-hidden h-[6.5rem] drop-shadow-[0_1.1rem_1rem_rgba(58,118,247,0.2)] border-b border-[#3EBAF6]'>
            <button onClick={() => {setSelectedFlag('All')}} className={`text-base sm:text-[2rem] font-[500] text-[#1E3365] mx-[0] xs:mx-[1rem] lg:mx-[2.2rem] px-[1rem] lg:px-[2rem] h-[6.4rem] ${selectedFlag === "All" ? "!font-[600] border-b-2 sm:border-b-4 border-[#1E3365]" : ""}`}>
                Awards
            </button>
            <button onClick={() => {setSelectedFlag('Blog')}} className={`text-base sm:text-[2rem] font-[400] text-[#1E3365] mx-[0] xs:mx-[1rem] lg:mx-[2.2rem] px-[1rem] lg:px-[2rem] h-[6.4rem] ${selectedFlag === "Blog" ? "!font-[600] border-b-2 sm:border-b-4 border-[#1E3365]" : ""}`}>
                Blog
            </button>
            <button onClick={() => {setSelectedFlag('Whitepaper')}} className={`text-base sm:text-[2rem] font-[400] text-[#1E3365] mx-[0] xs:mx-[1rem] lg:mx-[2.2rem] px-[1rem] lg:px-[2rem] h-[6.4rem] ${selectedFlag === "Whitepaper" ? "!font-[600] border-b-2 sm:border-b-4 border-[#1E3365]" : ""}`}>
                Whitepaper
            </button>
            <button onClick={() => {setSelectedFlag('Newsletter')}} className={`text-base sm:text-[2rem] font-[400] text-[#1E3365] mx-[0] xs:mx-[1rem] lg:mx-[2.2rem] px-[1rem] lg:px-[2rem] h-[6.4rem] ${selectedFlag === "Newsletter" ? "!font-[600] border-b-2 sm:border-b-4 border-[#1E3365]" : ""}`}>
                Newsletter
            </button>
            <button onClick={() => {setSelectedFlag('Podcast')}} className={`text-base sm:text-[2rem] font-[400] text-[#1E3365] mx-[0] xs:mx-[1rem] lg:mx-[2.2rem] px-[1rem] lg:px-[2rem] h-[6.4rem] ${selectedFlag === "Podcast" ? "!font-[600] border-b-2 sm:border-b-4 border-[#1E3365]" : ""}`}>
                Podcast
            </button>
            <button onClick={() => {setSelectedFlag('News Event')}} className={`text-base sm:text-[2rem] font-[400] text-[#1E3365] mx-[0] xs:mx-[1rem] lg:mx-[2.2rem] px-[1rem] lg:px-[2rem] h-[6.4rem] ${selectedFlag === "News Event" ? "!fon 600] borde2t-[sm:600] border-b-4 border-[#1E3365]" : ""}`}>
                News Event
            </button>
        </div>
    )
}

export default LatestInsightsNavbar
