import React, { useEffect, useState } from 'react'
import { PortfolioImgs, ProductImgs } from "../../Static/constants/imgConstants/imgConstants"
import PortfoliosSec from '../../Components/main/PortfoliosSec/PortfoliosSec';
import PrimaryLayout from '../../Components/common/layout/PrimaryLayout';
import HomeLandingSections from '../../Components/common/HomeLandingSections/HomeLandingSections';
import { useNavigate } from 'react-router-dom';

function Products({ setSelectedPortfolio, setSelectedCard, selectedProduct, setSelectedProduct }) {

    const navigate = useNavigate();

    const [data] = useState([
        {
            id: "id-card-printer",
            title: "ID Card Printer",
            content: [
                "The High Definition ID card printer is ideal for organizations of any size that need retransfer printing technology to routinely issue secure and durable, high definition IDs or cards at high speeds. As a cost-effective, eco-friendly and reliable solution, the HD printer provides high resolution 600 dpi printing capability for superior text and image quality.",
                "Designed to meet the needs of cost- and efficiency-conscious organizations, the HD printer produces cards at unprecedented speeds and leverages innovative wasteless lamination technology―helping cut your high-volume lamination materials costs by up to 40%.",
                "Secure World is ideal for organizations of any size that need retransfer printing technology to routinely issue secure and durable, high definition IDs or cards at high speeds. As a cost-effective, eco-friendly and reliable solution, different printers provide high resolution 300 dpi /600 dpi printing capability for superior text and image quality.",
                "Designed to meet the needs of cost- and efficiency-conscious organizations, the HDP produces cards at unprecedented speeds and leverages our innovative wasteless lamination technology―helping cut your high-volume lamination materials costs by up to 40%."
            ],
            bgImg: ProductImgs.ProductImg1,
            isReverse: false,
        },
        {
            id: "time-terminal",
            title: "Time Terminal",
            content: [
                "Face Recognition Terminals support quick and easy registration via multiple access methods, and convenient device configuration via web or GUI as well. More conveniently, users can remotely check live view, unlock door, check event logs and more, via the Time-connect App on their smartphone.",
                <>
                    <div className='flex justify-start items-start'>
                        <div className="text-black min-w-[.8rem] min-h-[.8rem] bg-[#000] rounded-full mt-[1.1rem] mr-8" />
                        Access methods include card, PIN code, fingerprint, face, and iris
                    </div>
                    <div className='flex justify-start items-start'>
                        <div className="text-black min-w-[.8rem] min-h-[.8rem] bg-[#000] rounded-full mt-[1.1rem] mr-8" />
                        Supports web or GUI configuration
                    </div>
                    <div className='flex justify-start items-start'>
                        <div className="text-black min-w-[.8rem] min-h-[.8rem] bg-[#000] rounded-full mt-[1.1rem] mr-8" />
                        Remote control via Time-Connect App
                    </div>
                </>,
                "Meuron AI based Face terminal is the most advanced face recognition terminal providing loads of the world's best features. Powered by Meuron's latest innovation in facial biometrics, AI based Face terminal offers unrivalled matching speed, accuracy and level of security. It can be field adjustable options like distance and brightness, which provides ease of work based on premises.",
                "Face terminal able to work all-round confidence regardless of lighting conditions. An ergonomically-designed structure, it provides exceptional performance and usability for diverse access control and time attendance sites, large or small.",

            ],
            bgImg: ProductImgs.ProductImg2,
            isReverse: true,
        },
        {
            id: "access-control",
            title: "Access Control",
            content: [
                "Access Control Solutions is a leader in providing advanced access control systems for businesses and organisations worldwide. Our comprehensive range of solutions includes cutting-edge biometric access control, RFID readers, and cloud-based access management software.",
                "With a focus on security, convenience, and scalability, Access Control Solutions empowers businesses to effectively manage access permissions, enhance security, and streamline operations. From small businesses to large enterprises, we tailor our solutions to meet the unique needs of each client, ensuring peace of mind and maximum efficiency.",
                "When it comes to deciding who has access to what, various departments are usually involved. This can include HR, facilities management and IT, as well as security. Often, these departments each have their own system that operates independently. This is inefficient though and can lead to mistakes that present significant security risks.",
                "For optimum security and efficiency, all systems should be aligned. Our access control system that has the ability to integrate with other systems can have the power to link everything together.",
            ],
            bgImg: ProductImgs.ProductImg3,
            isReverse: false,
        },
        {
            id: "smart-card-reader",
            title: "Smart Card & Reader",
            content: [
                "We have complete range of cards included Mifare, Proximity, HID, iclass, including:",
                <>
                    <div className='flex justify-start items-start'>
                        <div className="text-black min-w-[.8rem] min-h-[.8rem] bg-[#000] rounded-full mt-[1.1rem] mr-8" /> 
                        100% PVC cards in a variety of sizes and formats
                    </div>
                    <div className='flex justify-start items-start'>
                        <div className="text-black min-w-[.8rem] min-h-[.8rem] bg-[#000] rounded-full mt-[1.1rem] mr-8" /> 
                        Composite PVC/PET cards that stand up to the high heat involved in the lamination process or reverse transfer printing
                    </div>
                    <div className='flex justify-start items-start'>
                        <div className="text-black min-w-[.8rem] min-h-[.8rem] bg-[#000] rounded-full mt-[1.1rem] mr-8" /> 
                        A variety of proximity cards for access control applications
                    </div>
                    <div className='flex justify-start items-start'>
                        <div className="text-black min-w-[.8rem] min-h-[.8rem] bg-[#000] rounded-full mt-[1.1rem] mr-8" /> 
                        Holographic cards with embedded features for heightened security
                    </div>
                    <div className='flex justify-start items-start'>
                        <div className="text-black min-w-[.8rem] min-h-[.8rem] bg-[#000] rounded-full mt-[1.1rem] mr-8" /> 
                        Earth friendly cards that perform similarly to PVC cards, but are biodegradable
                    </div>
                </>,
                "Multiple technology proximity card offers proximity, magnetic stripe,smart chip and photo identification technologies on a single access control proximity card. Proximity card with magnetic stripe is ISO 7810compliant, with a nominal thickness of .030. It features a graphics quality surface optimized for Photo ID printing.",
                "Same size and thickness as a standard credit card enable users to keep in the pocket and carry to use any premises in the factory /offices.",
                "proximity card with magnetic stripe can be produced with visual security and anti-counterfeiting features such as holograms, ultra-violet fluorescent inks, micro-printing, or a custom logo to quickly and easily identify genuine cards."

            ],
            bgImg: ProductImgs.ProductImg5,
            isReverse: true,
        },
        {
            id: "turnstile",
            title: "Turnstile",
            content: [
                "Turnstile Solutions specialises in innovative access control systems, offering a range of turnstile solutions designed to enhance security and streamline entry management.",
                "From traditional waist-high turnstiles to cutting-edge optical and RFID turnstiles, we provide customisable solutions to meet the unique needs of our clients. With a focus on reliability, efficiency, and user-friendly design, Turnstile Solutions ensures seamless access control for facilities of all sizes and industries.",
                "Secure World is specialized in Speed Gates, Swing Barriers, Flap Barriers, Waist Height Turnstiles, and Tripod Turnstiles. Turnstile gates are a suitable contactless solution for access control in locations with high aesthetic and comfort criteria because of their modern, attractive design. Stainless steel is used to make the gate posts, tempered glass is used for the swing panels, top covers, and filler panels. For restricted physical entry to buildings, locations, and particularly secured zones, we are one of the leading suppliers of Turnstile gates in UAE."

            ],
            bgImg: ProductImgs.ProductImg6,
            isReverse: false,
        },
        {
            id: "Gemalto-passport",
            title: "Gemalto 3M passport",
            content: [
                "Gemalto 3M Passport Solutions provides advanced passport and identity management solutions for governments and organisations worldwide. With state-of-the-art technology and a commitment to security and efficiency, Gemalto 3M Passport Solutions offers comprehensive solutions for passport issuance, authentication, and border control.",
                "From secure document production to cutting-edge biometric authentication, we empower governments and organisations to enhance security, streamline processes, and protect identities in an increasingly connected world.",
                "3M full-page passport reader is a document information collection device with rich functions and novel design. It has industry-leading image processing, OCR text recognition, artificial intelligence, radio frequency identification (RFID), etc. Algorithm technology can quickly and accurately identify more than 80 types of document information and national ID cards information (in line with ICAO 9303 standard travel documents and Non-ICAO 9303 standard travel documents), support automatic sensor triggering of documents, automatic classification, and simple operation.",


            ],
            bgImg: ProductImgs.ProductImg4,
            isReverse: true,
        },
        {
            id: "digital-kiosk",
            title: "Digital Kiosk",
            content: [
                "Digital Kiosk offers innovative digital solutions, providing dynamic and interactive kiosk displays designed to enhance customer experiences. From engaging digital signage to intuitive touchscreen interfaces, we empower businesses to captivate audiences and drive meaningful engagement.",
                "With a focus on innovation and customer satisfaction, Digital Kiosk delivers impactful solutions that redefine the digital landscape.",
                "Our Digital and Interactive Kiosks have industrial-grade LCD boards. They have high luminosity, so the contents are visible even in direct sunlight. 700 nits brightness for indoors and up to 4000 nits for outdoors.",
                "The professionally ventilated and airtight design prevents dust or water from affecting the electronic components, achieving an IP55/65 protection rating, and making the unit suitable for any outdoor environment.",
                "Our professional LCD screen is protected by high-quality anti-reflective glass, which improves brightness, reduces reflections and activates the capacitive sensitivity of the glass. with ten touch points (multi-touch).",
                "The hardware is designed for industrial use, and due to its self-test and repair mechanism, the hard drive and the motherboard withstand more than 10,000 forced shutdowns."

            ],
            bgImg: ProductImgs.ProductImg7,
            isReverse: false,
        },
    ]);

    useEffect(() => {
        setTimeout(() => {
            if (selectedProduct) {
                window.location.href = "#" + selectedProduct
            }
        }, 1000);
    }, [selectedProduct])

    return (
        <div className='portfolios-sec'>
            <PrimaryLayout
                showNavbar={true}
                showContactUs={true}
                showFooterMain={true}
                showFooterMini={true}
                isContactUsAltBg={true}
                setSelectedPortfolio={setSelectedPortfolio}
                setSelectedCard={setSelectedCard}
                setSelectedProduct={setSelectedProduct}
            >
                <HomeLandingSections
                    title1={`Forging Future`}
                    title2={`\nFrontiers`}
                    subtitle={`Pioneering Innovation\nin Robotic Process Automation (RPA) .`}
                    route={"portfolio"}
                />
                <div className="our-offerings-sec bg-[#F7F7FF] py-[2rem]">
                    <div className="container-lg flex flex-col justify-center items-start">
                        <h1 className="text-[4rem] lg:text-[5rem] font-[500] text-[#00E0FF]">
                            Our Products
                        </h1>
                        <h2 className='text-[2rem] lg:text-[3rem] text-[#FFFFFF] font-[500] leading-[3rem] lg:leading-[4rem]'>
                            We are purely an IT projects company into Software & IT Automation with Latest Surveillance Securities.
                        </h2>
                        <p className="text-[#FFFFFF] text-[2.1rem]">
                            Revolutionizing Surveillance Security Through IT Automation.
                        </p>
                    </div>
                </div>
                {data.map((item) => {
                    return (
                        <PortfoliosSec
                            id={item.id}
                            title={item.title}
                            content={item.content}
                            bgImg={item.bgImg}
                            isReverse={item.isReverse}
                        />
                    )
                })
                }
            </PrimaryLayout>
        </div>
    )
}

export default Products