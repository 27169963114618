import React from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'

function AboutUsComponent() {
    const data = [
        "We are counted amongst the leading access control system company in Dubai. With the security threat increasing with each passing day, having reliable security cards, card readers, access controllers, alarm management systems, video recorders, etc., has become indispensable for any organization. At Secure World, we bring together the expertise in the security industry and cutting-edge technology to create effective security solutions for your enterprise.",
        "With expertise, teamwork, and integrity as our core values, we ensure that our systems, software, and security solutions win the trust of our clients and build long-term relations. Our entire team works with the sole aim to provide our clients with the most effective and efficient tools to set up a strong security infrastructure. Being one of the best access control system company in Dubai, we analyze your business working, its systems, and security requirements to come up with the most effective tools to create a strong security layer.",
        "We offer various solutions to meet your company’s business needs. These are ID card printer systems, access control systems, smart cards & smart card readers, turnstiles, enterprise IP video and alarm management software, and different types of ID cards. You can contact our team anytime and we would draft the best security solution according to your requirements.",
        "We proudly announce that Secure World has received “The Best High Tech Security Company 2018 UAE Business Awards” by MEA Market 2018 organized by AIGLOBALMEDIA, UK.",
    ]
    
    return (
        <div className="relative py-[1rem] bg-[#F0F4FF]">
            <div className='page-info-floating container-lg flex flex-col justify-start items-start p-[5rem] bg-[#FFFFFF] rounded-[3.6rem] shadow-[0_.4rem_6.4rem_0_rgba(22,60,96,0.10)] relative'>
                <SectionHeaders
                    contentPrimary="About "
                    contentSecondary="Us"
                    classes="text-[4rem] lg:text-[5rem] font-[500] text-[#008ED4] !mt-[-3.5rem]"
                />
                {
                    data.map((items) => {
                        return (
                            <p className='text-[2rem] sm:text-[2.4rem] font-[400] text-[#404040] text-justify my-[.8rem] sm:my-[2rem]'>
                                {items}
                            </p>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AboutUsComponent