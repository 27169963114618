import React, { useEffect, useState } from 'react'
import { NavbarImgs } from '../../../Static/constants/imgConstants/imgConstants'
import { useNavigate } from 'react-router-dom';
import OutsideAlerter from '../../common/OutsideAlerter/OutsideAlerter';

function Navbar({ setSelectedPortfolio }) {
    const navigate = useNavigate(); 

    const activeNav = window.location.href.split("/")[window.location.href.split("/").length -1];
    
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    useEffect(() => {
        // if(activeNav === "#about-us"){
        //     window.scrollBy(0, 900);
        //     return;
        // }
        window.scrollTo(0,0);
    }, [activeNav]);

    return (
        <nav id="navbar" className={`navbar transition ease-in duration-100 flex justify-center items-center w-full h-[10rem] fixed top-0 z-[80]`}>
            <div className='container-lg flex justify-between items-center py-[2rem]'>
                <div
                    className='nav-logo cursor-pointer w-[18.8rem]'
                    onClick={() => {setSelectedPortfolio(''); navigate("/") }}
                >
                    <img
                        className='w-full'
                        src={NavbarImgs.websiteLogo}
                        alt='logo'
                    />
                </div>
                <div className='hidden sm:flex justify-end items-center'>
                    <div className='flex flex-col justify-between items-center mx-[1.6rem]' onClick={() => {setSelectedPortfolio('');}} >
                        <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/'>
                            Home
                        </a>
                        {activeNav === '' || activeNav === '#' || activeNav === '#contact-us' ? <div className='w-full h-[.3rem] bg-white rounded-[.3rem]' /> : <div className='w-full h-[.3rem]' />}
                    </div>
                    <div className='group flex flex-col justify-between items-center mx-[1.6rem] cursor-pointer relative' onClick={() => {setSelectedPortfolio('');}}>
                        <div className='nav-link text-base text-[#FFFFFF] mb-[.4rem]'>
                            Services
                        </div>
                        {activeNav === 'fintech-solutions' || activeNav === 'fintech-solutions#atm-ims' || activeNav === 'fintech-solutions#digital-payment-platform' || activeNav === 'fintech-solutions#smart-saving-platform' || activeNav === 'fintech-solutions#door-pay' || activeNav === 'innovative-security-products' || activeNav === 'innovative-security-products#ai-based-time-attendance' || activeNav === 'innovative-security-products#high-level-document-security' || activeNav === 'innovative-security-products#smart-meeting' || activeNav === 'innovative-security-products#visitor-management' || activeNav === 'innovative-security-products#q-management' || activeNav === 'innovative-security-products#passport-solution' || activeNav === 'innovative-security-products#cheque-printing' || activeNav === 'innovative-security-products#enterprise-access-control' || activeNav === 'innovative-security-products#other-services' ? <div className='w-full h-[.3rem] bg-white rounded-[.3rem]' /> : <div className='w-full h-[.3rem]' />}
                        <div className='absolute pt-[6.5rem] p-4 invisible group-hover:visible'>
                            <div className='flex flex-col justify-between items-center mx-[1.6rem] bg-[#14538c]'>
                                <div className='flex flex-col justify-between items-center mx-[1.6rem] my-[.8rem]' onClick={() => {setSelectedPortfolio('');}}>
                                    <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/fintech-solutions'>
                                        Fintech
                                    </a>
                                </div>
                                <div className='flex flex-col justify-between items-center mx-[1.6rem] my-[.8rem]' onClick={() => {setSelectedPortfolio('');}}>
                                    <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/innovative-security-products'>
                                        Security
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col justify-between items-center mx-[1.6rem]'onClick={() => {setSelectedPortfolio('');}}>
                        <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/products'>
                            Products
                        </a>
                        {activeNav === 'products' || activeNav === "products#contact-us" || activeNav === "products#id-card-printer" || activeNav === "products#time-terminal" || activeNav === "products#access-control" || activeNav === "products#smart-card-reader" || activeNav === "products#turnstile" || activeNav === "products#Gemalto-passport" || activeNav === "products#digital-kiosk" || activeNav === "products#cctv"  || activeNav === "products#visitor-management"  ? <div className='w-full h-[.3rem] bg-white rounded-[.3rem]' /> : <div className='w-full h-[.3rem]' />}
                    </div>
                    <div className='flex flex-col justify-between items-center mx-[1.6rem]'onClick={() => {setSelectedPortfolio('');}}>
                        <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/portfolios'>
                            Portfolios
                        </a>
                        {activeNav === 'portfolios' || activeNav === "portfolios#contact-us" ? <div className='w-full h-[.3rem] bg-white rounded-[.3rem]' /> : <div className='w-full h-[.3rem]' />}
                    </div>
                    <div className='flex flex-col justify-between items-center mx-[1.6rem]'onClick={() => {setSelectedPortfolio('');}}>
                        <a className='nav-link text-base text-[#FFFFFF] mb-[.4rem]' href='/about-us'>
                            About Us
                        </a>
                        {activeNav === 'about-us' || activeNav === "about-us#contact-us" ? <div className='w-full h-[.3rem] bg-white rounded-[.3rem]' /> : <div className='w-full h-[.3rem]' />}
                    </div>
                </div>
                <div className='flex sm:hidden flex-col justify-between items-center relative'>
                    <OutsideAlerter isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
                        <div className={`nav-icon ${isMenuOpen && "open"}`} onClick={() => {setIsMenuOpen(!isMenuOpen)}}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className={`absolute top-[6.5rem] w-[35rem] ${isMenuOpen ? "left-[-12rem] ease-in duration-300" : "left-[16rem] ease-out duration-300" }`}>
                            <div className='flex flex-col justify-start items-start py-[1.2rem] bg-[#14538c]'>
                                <div className='flex flex-row-reverse justify-between items-center mx-[.3rem]' onClick={() => {setSelectedPortfolio('');}}>
                                    <a className={`nav-link text-base ${activeNav === '' || activeNav === '#' || activeNav === '#contact-us' ? "border-l-2 border-[#80c7ea] text-[#80c7ea] scale-[105%] font-[600]" : "text-[#FFFFFF]"} py-[.7rem] px-[1.6rem] mb-[.4rem]`} href='/#'>
                                        Home
                                    </a>
                                </div>
                                <div className='flex flex-row-reverse justify-between items-center mx-[.3rem]' onClick={() => {setSelectedPortfolio('');}}>
                                    <a className={`nav-link text-base ${activeNav === 'fintech-solutions' ? "border-l-2 border-[#80c7ea] text-[#80c7ea] scale-[105%] font-[600]" : "text-[#FFFFFF]"} py-[.7rem] px-[1.6rem] mb-[.4rem]`} href='/fintech-solutions'>
                                        Fintech
                                    </a>
                                </div>
                                <div className='flex flex-row-reverse justify-between items-center mx-[.3rem]' onClick={() => {setSelectedPortfolio('');}}>
                                    <a className={`nav-link text-base ${activeNav === 'innovative-security-products' ? "border-l-2 border-[#80c7ea] text-[#80c7ea] scale-[105%] font-[600]" : "text-[#FFFFFF]"} py-[.7rem] px-[1.6rem] mb-[.4rem]`} href='/innovative-security-products'>
                                        Security
                                    </a>
                                </div>
                                <div className='flex flex-row-reverse justify-between items-center mx-[.3rem]' onClick={() => {setSelectedPortfolio('');}}>
                                    <a className={`nav-link text-base ${activeNav === "products" || activeNav === "products#contact-us" ? "border-l-2 border-[#80c7ea] text-[#80c7ea] scale-[105%] font-[600]" : "text-[#FFFFFF]"} py-[.7rem] px-[1.6rem] mb-[.4rem]`} href='/products'>
                                        Products
                                    </a>
                                </div>
                                <div className='flex flex-row-reverse justify-between items-center mx-[.3rem]' onClick={() => {setSelectedPortfolio('');}}>
                                    <a className={`nav-link text-base ${activeNav === "portfolios" || activeNav === "portfolios#contact-us" ? "border-l-2 border-[#80c7ea] text-[#80c7ea] scale-[105%] font-[600]" : "text-[#FFFFFF]"} py-[.7rem] px-[1.6rem] mb-[.4rem]`} href='/portfolios'>
                                        Portfolios
                                    </a>
                                </div>
                                <div className='flex flex-row-reverse justify-between items-center mx-[.3rem]' onClick={() => {setSelectedPortfolio('');}}>
                                    <a className={`nav-link text-base ${activeNav === "about-us" || activeNav === "about-us#contact-us" ? "border-l-2 border-[#80c7ea] text-[#80c7ea] scale-[105%] font-[600]" : "text-[#FFFFFF]"} py-[.7rem] px-[1.6rem] mb-[.4rem]`} href='/about-us'>
                                        About Us
                                    </a>
                                </div>
                            </div>
                        </div>
                    </OutsideAlerter>
                </div>
            </div>
        </nav>
    )
}

export default Navbar