import React, { useState } from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import TestimonyCard from '../../common/TestimonyCard/TestimonyCard';
import { PrestigiousClientsImgs, TestimonyCardImgs } from '../../../Static/constants/imgConstants/imgConstants';
import SectionHeaders from '../../common/Headers/SectionHeaders';

function ClientTestimonial() {

    const [selectedIndex, setSelectedIndex] = useState(0);

    const items = [
        <TestimonyCard
            message={`We are extremely pleased with the Q management solution provided by Secure World. The effectiveness of the solution has truly surpassed our expectations. One of the standout features for us is its scalability. As our business has grown, the Q management solution seamlessly adapted to our increasing needs, proving its flexibility and robustness.`}
            image={PrestigiousClientsImgs.PrestigiousClientsImg6}
            imageWidth={"w-[15rem] lg:w-[25rem] [@media(min-width:1319px)]:w-[37.9rem] mt-[1.2rem]"}
            name={"SDI"}
            classNames={selectedIndex === 0 || selectedIndex === 5 ? "" : "opacity-10"}
        />,
        <TestimonyCard
            message={`We have implemented the facial recognition-based time attendance system from Secure World, and we are highly satisfied with the results. The technology has proven to be an excellent solution for our workforce management needs. The accuracy of facial recognition ensures reliable and secure attendance tracking.`}
            image={PrestigiousClientsImgs.PrestigiousClientsImg7}
            imageWidth={"w-[15rem] lg:w-[25rem] [@media(min-width:1319px)]:w-[37.9rem] mt-[2.4rem]"}
            name={"RTA"}
            classNames={selectedIndex === 1 ? "" : "opacity-10"}
        />,
        <TestimonyCard
            message={`The convenience offered by this system is noteworthy – our employees find it easy to use, and it streamlines our attendance management process. Secure World's facial recognition technology has significantly improved our overall efficiency in tracking attendance, reducing manual efforts, and minimizing errors.`}
            image={PrestigiousClientsImgs.PrestigiousClientsImg5}
            imageWidth={"w-[15rem] lg:w-[25rem] [@media(min-width:1319px)]:w-[37.9rem]"}
            name={"British School"}
            classNames={selectedIndex === 2 ? "" : "opacity-10"}
        />,
        <TestimonyCard
            message={`Our team and users in the UAE have found the health app to be an excellent tool. Its user-friendly interface and comprehensive features make it a great asset for promoting wellness and healthcare management. The app's functionality has proven to be valuable for both our internal team and the broader user community.`}
            image={PrestigiousClientsImgs.PrestigiousClientsImg8}
            imageWidth={"w-[15rem] lg:w-[25rem] [@media(min-width:1319px)]:w-[37.9rem] mt-[2.5rem]"}
            name={"MOCD"}
            classNames={selectedIndex === 3 ? "" : "opacity-10"}
        />,
        <TestimonyCard
            message={`Equally noteworthy is the exceptional support we've received from the Secure World team. Their support has been nothing short of outstanding. The team's responsiveness and dedication to addressing our queries and concerns have played a crucial role in our positive experience. Their commitment to customer satisfaction is evident in the prompt and effective assistance we receive.`}
            image={PrestigiousClientsImgs.PrestigiousClientsImg9}
            imageWidth={"w-[15rem] lg:w-[25rem] [@media(min-width:1319px)]:w-[37.9rem] mt-[1rem]"}
            name={"Emirates NBD"}
            classNames={selectedIndex === 4 ?  "" : "opacity-10"}
        />,
    ];

    return (
        <div className="client-testimonial w-full pb-[2rem]">
            <div className='client-testimonial-container container-lg flex flex-col justify-center items-start'>
                <SectionHeaders
                    contentPrimary="Client "
                    contentSecondary="Testimonial"
                    classes="text-[4rem] lg:text-[5rem] font-[500] text-[#008ED4] !mt-[0]"
                />
                <p className="text-[#3E3E3E] text-[2.1rem] my-[1.2rem]">
                    Secure World, known for 15 years of security excellence in the UAE, ventures into Fintech, aiming to make finance accessible and secure. They offer innovative IT solutions for businesses, providing expert care and Fortune 500-quality infrastructure at affordable prices.
                </p>
                <AliceCarousel
                    autoPlayInterval={4000}
                    infinite
                    autoPlayStrategy='default'
                    autoPlay
                    mouseTracking
                    keyboardNavigation={true}
                    items={items}
                    paddingRight={window.matchMedia("(min-width: 993px)").matches ? 250 :window.matchMedia("(min-width: 768px)").matches ? 180 :window.matchMedia("(min-width: 576px)").matches ? 100 : 40}
                    activeIndex={0}
                    onSlideChanged={(e) => {
                        if(e.item === -1){
                            setSelectedIndex(4);
                        }else {
                            setSelectedIndex(e.item);
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default ClientTestimonial
