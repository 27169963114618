import React from 'react'

function ServicesCard({ index, id, icon, title, content }) {
    return (
        <div 
            id={id} 
            className={`
                services-card cursor-pointer h-[30rem] lg:h-[25rem] px-[4rem] py-[1rem] 
                ${index < 4 ? "hover:shadow-[inset_0_-2rem_10rem_1rem_rgba(63,42,131,0.15)]" : "hover:shadow-[inset_0_2rem_10rem_1rem_rgba(63,42,131,0.15)]"}
                ${index < 4 ? "md:border-b md:border-[#3EBAF6]/[.5]" : ""}
                ${index !== 3 && index !== 7 ? "md:border-r md:border-[#3EBAF6]/[.5]" : ""}
            `} 
        > 
            <a href={`#${id}`} className='flex flex-col justify-center items-center'>
                <div className='services-card w-[6.8rem] h-[7rem] my-[2rem]'>
                    <img 
                        className='w-full h-full'
                        src={icon}
                        alt='icon'
                    />
                </div>
                <pre className='text-[2rem] lg:text-3xl font-[600] text-[#000] text-Montserrat-sans-serif text-center'>
                    {title}
                </pre>
                <h2 className='text-base lg:text-lg font-[500] text-[#3E3E3E] text-center text-DM-Sans-sans-serif'>
                    {content}
                </h2>
            </a>
        </div>
    )
}

export default ServicesCard
