import React from 'react'
import PrimaryLayout from '../../Components/common/layout/PrimaryLayout'
import PageInfoFloating from '../../Components/common/PageInfoFloating/PageInfoFloating'
import InfominezBanner from '../../Components/common/InfominezBanner/infominezBanner'
import ClientTestimonial from '../../Components/main/ClientTestimonial/ClientTestimonial'
import PortfolioSec from '../../Components/main/PortfolioSec/PortfolioSec'
import PrestigiousClients from '../../Components/main/PrestigiousClients/PrestigiousClients'
import OurOfferingsSec from '../../Components/main/OurOfferingsSec/OurOfferingsSec'
import HomeMainLadingSecComponent from '../../Components/main/HomeMainLadingSecComponent/HomeMainLadingSecComponent'

function Home({ setSelectedPortfolio, setSelectedCard, setSelectedProduct }) {
  return (
    <PrimaryLayout
      showNavbar={true}
      showContactUs={true}
      showFooterMain={true}
      showFooterMini={true}
      isContactUsAltBg={false}
      setSelectedPortfolio={setSelectedPortfolio}
      setSelectedCard={setSelectedCard}
      setSelectedProduct={setSelectedProduct}
    >
      <HomeMainLadingSecComponent/>
      <OurOfferingsSec />
      <PortfolioSec setSelectedPortfolio={setSelectedPortfolio}/>
      <InfominezBanner />
      <ClientTestimonial/>
      <PrestigiousClients />
      <PageInfoFloating
        details={"We are counted amongst the leading access control system company in Dubai. With the security threat increasing with each passing day, having reliable security cards, card readers, access controllers, alarm management systems, video recorders, etc., has become indispensable for any organization. At Secure World, we bring together the expertise in the security industry and cutting-edge technology to create effective security solutions for your enterprise."}
      />
    </PrimaryLayout>
  )
}

export default Home